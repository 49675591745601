import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
	LoginPage,
	SignupPage,
	CertificatePage,
	WriteDoctorInfoPage,
	CounselingPage,
	DetailPage,
	PersonalBrandingPage,
	MyInfoUpdatePage,
	NaverLoginCallback,
	SharePage,
} from 'pages';
import { PrivateRoute } from './privateRoute';
import { LoginRoute } from './loginRoute';
import AssociatePage from 'pages/associatePage';
import SharedDetailPage from 'pages/slackSharedDetailPage';

export function Router() {
	return (
		<Routes>
			<Route
				path='/'
				element={
					<LoginRoute>
						<LoginPage />
					</LoginRoute>
				}
			/>
			<Route
				path='login'
				element={
					<LoginRoute>
						<LoginPage />
					</LoginRoute>
				}
			/>
			<Route
				path='/callback/naverLogin'
				element={
					<LoginRoute>
						<NaverLoginCallback />
					</LoginRoute>
				}
			/>
			<Route
				path='login/signup'
				element={
					<LoginRoute>
						<SignupPage />
					</LoginRoute>
				}
			/>
			<Route
				path='login/certificate'
				element={
					<LoginRoute>
						<CertificatePage />
					</LoginRoute>
				}
			/>
			<Route path='signup/writeinfo' element={<WriteDoctorInfoPage />} />

			<Route path='signup/associate' element={<AssociatePage />} />

			<Route
				path='counseling'
				element={
					<PrivateRoute>
						<CounselingPage />{' '}
					</PrivateRoute>
				}
			/>
			<Route
				path='counseling/:id'
				element={
					<PrivateRoute>
						<DetailPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='personalbranding'
				element={
					<PrivateRoute>
						<PersonalBrandingPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='myupdate'
				element={
					<PrivateRoute>
						<MyInfoUpdatePage />
					</PrivateRoute>
				}
			/>
			<Route path='share/:crypto' element={<SharePage />} />
			<Route path='shared-counseling/:cid/:hid/:pid' element={<SharedDetailPage />} />
		</Routes>
	);
}
