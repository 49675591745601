import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	addAllCounselingList,
	selectCard,
	addAllChatList,
	selectChat,
} from 'store/reducer/chatReducer';
import { HeaderBar } from 'components/header';
import { LoadingModal } from 'components/modal';
import { PreDiagnosisCard } from 'components/card/preDiagnosisCard';
import { SelectSymptom, SelectChat } from 'components/detail';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import useAxios from 'api/axiosCustom';
import { RootState } from 'store/reducer/rootReducer';
import moment from 'moment';
import routerConstant from 'data/constant/routerConstant';
import { PrimaryBtn } from 'components/button';
import { ErrorAlert } from 'components/alert';

export default function DetailPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const axios = useAxios();
	const dispatch = useDispatch();
	const doctorId = localStorage.getItem('kdDId') ?? 'NoDoctor';
	const [selectMenu, setSelectMenu] = useState<string>('증상보기');
	const [selectCounselingId, setSelectCounselingId] = useState<string>(location.state.id);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
	const counselingList = useSelector((state: RootState) => state.chat.allCounselingList);
	const counseling = useSelector((state: RootState) => state.chat.selectCounseling);
	const chatList = useSelector((state: RootState) => state.chat.allChatList);
	const selectChatInfo = useSelector((state: RootState) => state.chat.selectChat);
	const hospitalList = useSelector((state: RootState) => state.hospital.list);
	const myHospitalIdList = hospitalList.map((hospital) => hospital.id);
	useEffect(() => {
		if (doctorId !== 'NoDoctor') {
			(async () => {
				if (
					myHospitalIdList.length &&
					!myHospitalIdList.includes(location.state.hospitalId)
				) {
					setIsModalOpen(true);
					setTimeout(() => {
						setIsModalOpen(false);
						navigate('/');
					}, 3000);
				}
			})();
		}
	}, [myHospitalIdList]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const userCounselingId: any = { userCounselingId: location.state.id };
			const hospitalId: any = { hospitalId: location.state.hospitalId };
			const counselingRes = await axios.get(
				`/counseling/doctor/${location.state.hospitalId}/profileId/${location.state.profileId}`
			);
			dispatch(addAllCounselingList(counselingRes.data));
			const chatRes = await axios.get(
				`/counseling/doctor/${location.state.hospitalId}/chat/profileId/${location.state.profileId}`,
				userCounselingId
			);
			dispatch(addAllChatList(chatRes.data));
			setIsLoading(false);
			counselingRes.data.length === 0 && navigate(routerConstant.COUNSELING);
			if (location.state.menuState === '상담하기') {
				setSelectMenu('상담하기');
			}
		})();
	}, []);

	useEffect(() => {
		dispatch(selectCard(location.state.id));
	}, [counselingList]);

	useEffect(() => {
		dispatch(selectChat(location.state.id));
	}, [chatList]);

	function onMenuBtn(e: any) {
		setSelectMenu(e.target.innerText);
	}

	function onCounselingCard(chat: any) {
		setSelectCounselingId(chat.id);
		dispatch(selectCard(chat.id));
		dispatch(selectChat(chat.id));
	}

	const handleCopyCounseling = () => {
		const profileQA = counseling.Profile?.Questions.map(
			(question) =>
				' ' +
				question.mainQuestion +
				' : ' +
				question.ProfileQuestion.answer.replace(/;/g, ', ').replace(/,\s*$/, '')
		);

		const genderSwitch = (genderNum: number) => {
			switch (genderNum) {
				case 1:
					return '남자';
				case 2:
					return '여자';
				default:
					return '둘 다 아니에요';
			}
		};

		const symptomQA = counseling.Questions.map(
			(question) =>
				' ' +
				question.mainQuestion +
				' : ' +
				question.UserCounselingQuestion.answer
					.replace(/;/g, ', ')
					.replace(/,\s*$/, '')
		);

		const now = new Date();
		const year = now.getFullYear();
		const t = document.createElement('textarea');
		document.body.appendChild(t);
		t.value =
			' < 상담자 정보 > /n ' +
			'이름 : ' +
			counseling.Profile?.name +
			', ' +
			'나이 : ' +
			(year - Number(counseling.Profile?.birthDate.slice(0, 4)) + 1) +
			', ' +
			'성별 : ' +
			genderSwitch(Number(counseling.Profile?.gender)) +
			', ' +
			' < 프로필 질문 > ' +
			profileQA.join() +
			' < 증상 > ' +
			'진료과목 : ' +
			counseling.department.replace(/,\s*$/, '') +
			', ' +
			'증상 : ' +
			counseling.symptom.replace(/,\s*$/, '') +
			', ' +
			' < 특성 > ' +
			symptomQA.join();

		t.select();
		document.execCommand('copy');
		document.body.removeChild(t);

		setIsAlertOpen(true);
		setTimeout(() => {
			setIsAlertOpen(false);
		}, 2000);
	};

	return (
		<Cover>
			<HeaderBar nowPath={menuConstant.COUNSELING} />
			<LoadingModal isModalVisible={isLoading} />
			<ErrorAlert
				label='권한이 없는 사용자입니다. 병원에 소속된 계정으로 로그인 해주세요'
				toggle={isModalOpen}
			/>
			<ErrorAlert
				label={`${counseling?.Profile?.name}님의 문진 내용이 복사되었습니다.`}
				toggle={isAlertOpen}
				color='blue'
			/>
			{!isModalOpen && (
				<ContentsContainer>
					<SymptomContainer>
						<p className='mainText'>{counselingList[0]?.Profile?.name}</p>
						{/* <p className="mainText">{counselingList[0].Profile?.name[0]}**</p> */}
						<MenuContainer>
							<p
								className={
									selectMenu === '증상보기' ? 'select' : ''
								}
								onClick={onMenuBtn}>
								증상보기
							</p>
							<p
								className={
									selectMenu === '상담하기' ? 'select' : ''
								}
								onClick={onMenuBtn}>
								상담하기
							</p>
							<CopyBtnBox onClick={handleCopyCounseling}>
								<div>문진결과 텍스트로 복사하기</div>
							</CopyBtnBox>
						</MenuContainer>
						{selectMenu === '증상보기' ? (
							<SelectSymptom />
						) : (
							<SelectChat chat={selectChatInfo} />
						)}
					</SymptomContainer>
					<CounselingContainer>
						{counselingList[0]?.id === undefined ? null : (
							<>
								<p className='profileName'>
									{counselingList[0]?.Profile?.name} 님과의 상담
									{/* {counselingList[0].Profile?.name[0]}** 님과의 상담 */}
								</p>
								{counselingList.map((element: any) => (
									<PreDiagnosisCard
										isSelect={
											selectCounselingId === element.id
										}
										onClick={() =>
											onCounselingCard(element)
										}
										name={element.Profile?.name}
										gender={element.Profile?.gender}
										birth={element.Profile.birthDate}
										date={moment(element.createdAt).format(
											'MM월 DD일 HH:mm'
										)}
										state={element.status}
										department={element.department}
										symptom={element.symptom}
										mainText={element.additionalSymptom}
									/>
								))}
							</>
						)}
					</CounselingContainer>
				</ContentsContainer>
			)}
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	padding: 0;
`;

const ContentsContainer = styled.div`
	display: flex;
	margin-top: 42px;
	gap: 20px;
`;

const SymptomContainer = styled.div`
	position: relative;
	.mainText {
		position: absolute;
		top: -96px;
		left: 52px;
		margin: 0;
		font-size: 40px;
		font-weight: bold;
		width: 900px;
		text-overflow: ellipsis;
		overflow-y: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
`;

const MenuContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
	p {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: normal;
		margin-right: 16px;
		cursor: pointer;
	}
	.select {
		padding-bottom: 6px;
		border-bottom: solid 2px #1bbcff;
		font-weight: bold;
	}
`;

const CounselingContainer = styled.div`
	margin: 0;
	padding: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;
	.profileName {
		font-size: ${styleConstant.FONT_SIZE.medium};
		letter-spacing: -0.2px;
		line-height: 1.5;
		margin: 0;
		font-weight: bold;
	}
`;

const CopyBtnBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	text-align: center;
	cursor: pointer;
	background-color: ${styleConstant.COLOR.WHITE_BG};
	border-radius: 20px;
	border: 1px solid ${styleConstant.COLOR.CTA.DEFAULT};
	color: ${styleConstant.COLOR.CTA.DEFAULT};
	transition: all 0.3s;
	:hover {
		background-color: ${styleConstant.COLOR.CTA.DEFAULT};
		color: ${styleConstant.COLOR.WHITE_BG};
	}
	div {
		padding: 6px 10px;
		line-height: 1;
		text-align: center;
		position: relative;
		font-weight: 400;
	}
`;
