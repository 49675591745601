import React from 'react';
import styled from 'styled-components';
import { ReactComponent as QuestionIcon } from 'assets/icon/questionIcon.svg';
import styleConstant from 'data/constant/styleConstant';

interface NoticeCardProps {
	text: string;
	fontBold?: boolean;
	color?: string;
	onClick?: (event: React.MouseEvent<HTMLParagraphElement>) => void;
}

export function NoticeCard({ text, fontBold = true, onClick, color = 'grey' }: NoticeCardProps) {
	return (
		<Cover onClick={onClick} fontBold={fontBold} color={color}>
			<span>
				<QuestionIcon />
			</span>
			<span className='text'>{text}</span>
		</Cover>
	);
}

const Cover = styled.p<{ fontBold: true | false; color: string }>`
	display: flex;
	margin: 0 0 0 5px;
	font-size: ${styleConstant.FONT_SIZE.small};
	font-weight: ${({ fontBold }) => (fontBold ? 'bold' : 'normal')};
	line-height: ${styleConstant.LINE_HEIGHT.small};
	color: ${({ color }) =>
		color === 'blue' ? styleConstant.COLOR.CTA.DEFAULT : styleConstant.COLOR.GRAY_6};
	span {
		margin-right: 8px;
	}
	.text {
		margin-top: -1.5px;
	}
	cursor: pointer;
`;
