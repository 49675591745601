import React from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

interface PrimaryTextareaProps {
	label: string;
	value: string;
	placeholder?: string;
	height: string;
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export function PrimaryTextarea({
	label,
	value,
	placeholder,
	height,
	onChange,
}: PrimaryTextareaProps) {
	return (
		<Cover>
			{label !== '' ? <p>{label}</p> : null}
			<Primary
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				height={height}
			/>
		</Cover>
	);
}

const Cover = styled.div`
	width: 100%;
	p {
		text-align: start;
		margin: 0 0 8px;
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		line-height: ${styleConstant.LINE_HEIGHT.moreSmall};
		color: ${styleConstant.COLOR.GRAY_7};
		font-weight: 500;
	}
	margin: 0 0 16px 0;
`;

const Primary = styled.textarea<{ height: string }>`
	box-sizing: border-box;
	border: solid 1px #cfcfcf;
	border-radius: 20px;
	width: 100%;
	height: ${({ height }) => `${height}px`};
	padding: 12px 16px;
	font-size: ${styleConstant.FONT_SIZE.small};
	word-wrap: break-word;
	word-break: break-all;
	overflow: hidden;
	resize: none;

	&:focus {
		outline: none;
		border: solid 1px #1bbcff;
	}
`;
