import styleConstant from 'data/constant/styleConstant';
import React from 'react';
import styled from 'styled-components';
import { IsCheck } from './isCheck';
import { IsNotCheck } from './isNotCheck';

interface Props {
	isCheck: boolean;
	onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	isAgreeCheck: boolean;
	label: string;
}

const Cover = styled.div`
	display: flex;
	font-size: ${styleConstant.FONT_SIZE.small};
	font-weight: bold;
	line-height: ${styleConstant.LINE_HEIGHT.small};
	letter-spacing: -0.18px;
	align-items: center;
	margin: 0;
	word-break: keep-all;
	justify-content: space-between;
	div {
		display: flex;
		gap: 8px;
	}
	svg {
		cursor: pointer;
	}
`;

export function SingleCheck({ isCheck, onClick, isAgreeCheck, label }: Props) {
	return (
		<Cover>
			<div>
				{isCheck ? (
					<IsCheck onClick={onClick} />
				) : (
					<IsNotCheck onClick={onClick} agree={isAgreeCheck} />
				)}
				{label}
			</div>
		</Cover>
	);
}
