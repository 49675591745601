import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';
import { ReactComponent as TranslateBtn } from 'assets/icon/translateLogo.svg';
import axios from 'axios';

interface CategorySymptomProps {
	question: string;
	answer: string;
	last?: boolean;
	isImg?: boolean;
	answerType?: string;
	inputType?: string;
}

export function CategorySymptom({
	question,
	answer,
	last = false,
	isImg = false,
	answerType,
	inputType,
}: CategorySymptomProps) {
	const splitAnswer = answer.split(';').filter((answer) => answer !== '');

	const [textList, setTextList] = useState<string[]>(splitAnswer);
	const [lang, setLang] = useState<string>('en');
	const [textlanguageList, setTextlanguageList] = useState<{ lang: string; answer: string }[]>(
		[]
	);
	console.log('textlanguageList', textlanguageList);

	useEffect(() => {
		setTextList(splitAnswer);
	}, [answer]);

	const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;

	async function translateText(text: string[], idx: number) {
		const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

		setLang((prev) => (prev === 'en' ? 'ko' : 'en'));

		try {
			const response = await axios.post(apiUrl, {
				q: text[idx],
				target: 'ko',
			});

			const newTextList = [...textList];
			newTextList[idx] = response.data.data.translations[0].translatedText;

			setTextList(lang === 'ko' ? splitAnswer : newTextList);
		} catch (error) {
			console.error('Error during translation:', error);
			return null;
		}
	}

	function isStringNumbersAndSpecialChars(str: string) {
		const regex = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
		return regex.test(str);
	}

	useEffect(() => {
		const apiUrlDetect = `https://translation.googleapis.com/language/translate/v2/detect?key=${apiKey}`;

		(async () => {
			const answerLanguagePromises = await splitAnswer.map(async (answer) => {
				const responseDetect = await axios.post(apiUrlDetect, {
					q: answer,
				});
				return { lang: responseDetect.data.data.detections[0][0].language, answer };
			});
			const answerLanguageList = await Promise.all(answerLanguagePromises);
			setTextlanguageList(answerLanguageList);
		})();
	}, [answer]);

	return (
		<Cover last={last}>
			<p>{question}</p>

			<div className='answerContainer'>
				{textList?.map((element, idx) =>
					isImg ? (
						element.length === 0 ? null : (
							<a
								key={idx}
								href={element}
								className='click'
								target='_blank'
								rel='noreferrer'>
								확인하기
							</a>
						)
					) : (
						<p key={idx} className='answer'>
							{element}
							{textlanguageList[idx]?.lang &&
								textlanguageList[idx]?.lang !== 'ko' &&
								!isStringNumbersAndSpecialChars(element) && (
									<p
										key={idx}
										className='translate'
										onClick={() =>
											translateText(textList, idx)
										}>
										번역하기
									</p>
								)}
						</p>
					)
				)}
			</div>
		</Cover>
	);
}

const Cover = styled.div<{ last: true | false }>`
	margin-bottom: ${({ last }) => (last ? '0' : '24px')};
	p {
		font-size: ${styleConstant.FONT_SIZE.small};
		margin: 0;
	}
	.answerContainer {
		margin-top: 8px;
	}
	.answer {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0;
	}
	.translate {
		display: inline-block;
		font-weight: 500;
		font-size: ${styleConstant.FONT_SIZE.small};
		color: ${styleConstant.COLOR.CTA.DEFAULT};
		width: 65px;
		height: 23px;
		cursor: pointer;
		transform: translate(5px, 0px);
		//background-color: ${styleConstant.COLOR.WHITE_BG};
		//border-radius: 10px;
		//border: 1px solid ${styleConstant.COLOR.CTA.DEFAULT};
		text-align: center;
		line-height: 23px;
		:hover {
			filter: brightness(1.15);
		}
	}
	.click {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0;
		color: ${styleConstant.COLOR.CTA.DEFAULT};
		cursor: pointer;
		width: 85px;
	}
	a {
		display: block;
	}
`;
