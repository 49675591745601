import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { useSelector, useDispatch } from 'react-redux';
import { addAllCounseling, addSearchCounseling, allReset } from 'store/reducer/counselingReducer';
import { selectCard } from 'store/reducer/chatReducer';
import useObserver from 'shared/hook/useObserver';
import { LoadingModal } from 'components/modal';
import { PreDiagnosisCard } from 'components/card/preDiagnosisCard';
import { RootState } from 'store/reducer/rootReducer';
import moment from 'moment';
import { SearchInputContainer } from 'components/search';
import { useDebounce } from 'hooks/useDebounce';

export function AllCounseling() {
	const navigate = useNavigate();
	const axios = useAxios();
	const dispatch = useDispatch();

	const [page, setPage] = useState<number>(-10);
	const [isLimit, setIsLimit] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [birth, setBirth] = useState<string>('');
	const { setRef } = useObserver(
		async (entry: any, observer) => {
			observer.observe(entry.target);
			setPage((prevState) => (prevState += 10));
		},
		{ threshold: 0.9 }
	);
	// useEffect(() => {
	// 	(async () => {
	// 		dispatch(allReset());
	// 		setIsLoading(true);
	// 		const res = await axios.get(
	// 			`/counseling/doctor/${hospitalId}/search?name=${name}&birthDate=${birth}`
	// 		);
	// 		dispatch(addAllCounseling(res.data));
	// 		if (res.data.length < 10) {
	// 			setIsLimit(false);
	// 		} else {
	// 			setIsLimit(true);
	// 		}
	// 		setIsLoading(false);
	// 	})();
	// }, [hospitalId]);

	let counselingArr = useSelector((state: RootState) => state.counseling.counselingArr);
	let hospitalId = useSelector((state: RootState) => state.hospital.selectedHospitalId);

	function onCardClick(element: any) {
		navigate(`${element.id}`, {
			state: {
				id: element.id,
				profileId: element.ProfileId,
				hospitalId: element.hospitalId,
			},
		});
	}

	const debouncedInputNameText = useDebounce(name, 200);
	const debouncedInputBirthText = useDebounce(birth, 200);

	useEffect(() => {
		if (debouncedInputNameText || debouncedInputBirthText) {
			(async () => {
				setPage(0);
				dispatch(allReset());
				const res = await axios.get(
					`/counseling/doctor/${hospitalId}/search?&name=${debouncedInputNameText}&birthDate=${debouncedInputBirthText}`
				);
				setIsLimit(true);
				dispatch(addAllCounseling(res.data));
				if (res.data.length < 10) {
					setIsLimit(false);
				}
				setIsLoading(false);
			})();
		} else {
			(async () => {
				setPage(0);
				dispatch(allReset());
				const res = await axios.get(
					`/counseling/doctor/${hospitalId}/search?&name=${name}&birthDate=${birth}`
				);
				setIsLimit(true);
				dispatch(addAllCounseling(res.data));
				if (res.data.length < 10) {
					setIsLimit(false);
				}
			})();
		}
	}, [debouncedInputNameText, debouncedInputBirthText, hospitalId]);

	useEffect(() => {
		if (page > 9 && isLimit && hospitalId !== '0') {
			if (debouncedInputNameText || debouncedInputBirthText) {
				(async () => {
					const res = await axios.get(
						`/counseling/doctor/${hospitalId}/search?offset=${page}&name=${debouncedInputNameText}&birthDate=${debouncedInputBirthText}`
					);
					setTimeout(() => {
						dispatch(addAllCounseling(res.data));
					}, 200);

					if (res.data.length < 10) {
						setIsLimit(false);
					}
					setIsLoading(false);
				})();
			} else {
				(async () => {
					setIsLoading(true);
					const res = await axios.get(
						`/counseling/doctor/${hospitalId}/search?offset=${page}&name=${name}&birthDate=${birth}`
					);
					setTimeout(() => {
						dispatch(addAllCounseling(res.data));
					}, 200);
					if (res.data.length < 10) {
						setIsLimit(false);
					}
					setIsLoading(false);
				})();
			}
		}
	}, [page]);

	function onNameInput(e: any) {
		setName(e.target.value);
	}

	function onBirthInput(e: any) {
		const regex = /^[0-9\b -]{0,10}$/;
		if (regex.test(e.target.value)) {
			setBirth(e.target.value);
		}
	}

	return (
		<>
			<Cover>
				<LoadingModal isModalVisible={isLoading} />
				<SearchInputContainer
					page={page}
					setPage={setPage}
					isLimit={isLimit}
					setIsLimit={setIsLimit}
					name={name}
					setName={setName}
					birth={birth}
					setBirth={setBirth}
					onNameInput={onNameInput}
					onBirthInput={onBirthInput}
				/>
				{counselingArr.length === 0
					? null
					: counselingArr?.map((element, idx) => (
							<PreDiagnosisCard
								key={idx}
								name={element?.Profile?.name}
								gender={element?.Profile?.gender}
								birth={element?.Profile?.birthDate}
								date={moment(element.createdAt).format(
									'MM월 DD일 HH:mm'
								)}
								state={element.status}
								department={element.department}
								symptom={element.symptom}
								mainText={element.additionalSymptom}
								onClick={() => onCardClick(element)}
							/>
					  ))}
			</Cover>
			{<div style={{ height: '10px' }} ref={setRef} />}
		</>
	);
}

const Cover = styled.div`
	margin: 0;
	padding-bottom: 32px;
	display: flex;
	flex-wrap: wrap;
	gap: 24px 20px;
`;
