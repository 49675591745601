import React, { useEffect } from "react";

export default function NaverLogin() {
  // useEffect(() => {
  //   let loadEvent: any;
  //   const timeout = setTimeout(() => {
  //     if (typeof window !== "undefined") {
  //       const naverLogin = new window.naver.LoginWithNaverId({
  //         clientId: process.env.REACT_APP_NAVER_LOGIN_KEY,
  //         callbackUrl: "https://partner.knowingdoctor.com/callback/naverLogin",
  //         isPopup: false,
  //         callbackHandle: false,
  //       });
  //       naverLogin.init();

  //       loadEvent = window.addEventListener("load", function () {
  //         naverLogin.getLoginStatus(async function (status: any) {
  //           if (status) {
  //             window.opener.gotoLoginAccess(naverLogin);
  //             window.close();
  //             return;
  //           }
  //           return;
  //         });
  //       });
  //     }
  //   }, 100);

  //   return () => {
  //     window.removeEventListener("load", loadEvent);
  //     clearTimeout(timeout);
  //   };
  // }, []);

  return <div />;
}
