import React, { useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderBar } from 'components/header';
import { ErrorAlert } from 'components/alert';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import { RootState } from 'store/reducer/rootReducer';
import routerConstant from 'data/constant/routerConstant';
import { ReactComponent as StopWatchIcon } from 'assets/icon/signup/stopWatchIcon.svg';

export default function AssociatePage() {
	const navigate = useNavigate();

	return (
		<Cover>
			<HeaderBar noBack={true} loginPage={true} />

			<StopWatchIcon style={{ marginTop: '90px' }} />
			<p className='mainText'>{'조금만 기다려 주세요!'}</p>
			<p className='subText'>
				{'파트너 승인을 기다리고 있어요.'}
				<br />
				{'승인이 완료되면 휴대폰 번호로 안내해드릴 예정입니다.'}
				<br />
				{'승인까지 최대 3일이 소요될 수 있어요'}
			</p>
			<div className='btnBox'>
				<PrimaryBtn
					label='로그아웃하기'
					color='none'
					onClick={() => navigate('/')}
				/>
			</div>
		</Cover>
	);
}

const Cover = styled.div`
	width: ${styleConstant.DESKTOP_BODY_WIDTH};
	margin: ${styleConstant.DESKTOP_BODY_MARGIN};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	text-align: center;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 10px 0 0;
	}
	.subText {
		font-size: ${styleConstant.FONT_SIZE.medium};
		line-height: ${styleConstant.LINE_HEIGHT.medium};
		font-weight: 400;
		margin: 0 0 15px;
	}
	.btnBox {
		width: 240px;
	}
`;
