import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';

interface TwoBtnModalProps {
	isModalVisible: boolean;
	mainText: string;
	topText?: string;
	bottomText?: string;
	leftBtnText: string;
	rightBtnText: string;
	leftBtnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	rightBtnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	rightBtnColor?: 'blue' | 'red';
}

export function TwoBtnModal({
	isModalVisible,
	mainText,
	topText,
	bottomText,
	leftBtnText,
	rightBtnText,
	leftBtnClick,
	rightBtnClick,
	rightBtnColor = 'blue',
}: TwoBtnModalProps) {
	return (
		<StyledModal
			visible={isModalVisible}
			maskClosable={false}
			centered={true}
			closable={false}
			footer={null}>
			<TextContainer>
				<p className='subText'>{topText}</p>
				<p className='mainText'>{mainText}</p>
				<p className='subText'>{bottomText}</p>
			</TextContainer>
			<BtnContainer>
				<PrimaryBtn
					className='leftBtn'
					label={leftBtnText}
					onClick={leftBtnClick}
					color='none'
				/>
				<PrimaryBtn
					label={rightBtnText}
					onClick={rightBtnClick}
					color={rightBtnColor}
				/>
			</BtnContainer>
		</StyledModal>
	);
}

const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 20px;
	}
`;

const TextContainer = styled.div`
	text-align: center;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
	}
	.subText {
		font-size: ${styleConstant.FONT_SIZE.medium};
	}
`;

const BtnContainer = styled.div`
	display: flex;
	justify-content: space-between;
	.leftBtn {
		margin-right: 20px;
	}
`;
