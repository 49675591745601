import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import moment from 'moment';
import useCountDown from 'react-countdown-hook';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { authConfig } from 'lib/firebase/authConfig';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderBar } from 'components/header';
import { LoadingModal } from 'components/modal';
import { SignupInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { RequirementItem } from 'components/signup';
import { ReactComponent as CheckBoxIcon } from 'assets/icon/login/checkBox.svg';
import { ReactComponent as ArrowDown } from 'assets/icon/signup/arrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/icon/signup/arrowUp.svg';
import styleConstant from 'data/constant/styleConstant';
import routerConstant from 'data/constant/routerConstant';
import { RootState } from 'store/reducer/rootReducer';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ThreeBtnModal } from 'components/modal/threeBtnModal';
import { NoticeCard } from 'components/signup/noticeCard';
import { SingleCheck } from 'components/checkBox/singleCheck';

export default function SginupPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const axios = useAxios();
	const auth = authConfig('signup');
	const captchaRef = useRef<any>(null);

	const [timeLeft, { start }] = useCountDown(180 * 1000, 1000);
	const [isVerificationTimeLeft30s, setIsVerificationTimeLeft30s] = useState<boolean>(false);
	const [isVerified, setIsVerified] = useState<boolean>(false);
	const [isCheckListVisible, setIsCheckListVisible] = useState<boolean>(false);
	const [isAgeCheck, setIsAgeCheck] = useState<boolean>(false);
	const [isInqueryModalOpen, setIsInqueryModalOpen] = useState<boolean>(false);
	const [agreeState, setAgreeState] = useState<boolean>(false);
	const [isAgreeSignup, setIsAgreeSignup] = useState<boolean>(true);
	const [isRequirement, setIsRequirement] = useState<boolean>(true);
	const [requirementCheck, setRequirementCheck] = useState<number[]>([]);
	const [selectableCheck, setSelectableCheck] = useState<number>(0);
	const [isVerificationCheck, setIsVerificationCheck] = useState<boolean>(true);
	const [verificationValue, setVerificationValue] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const loginInfo = useSelector((state: RootState) => state.login);

	useEffect(() => {
		setIsLoading(true);
		const setTime = setTimeout(() => {
			setIsLoading(false);
		}, 1500);
		if (typeof window !== 'undefined') {
			start();
			window.recaptchaVerifier = new RecaptchaVerifier(
				captchaRef.current,
				{
					size: 'invisible',
					callback: () => {},
				},
				auth
			);
			signInWithPhoneNumber(
				auth,
				loginInfo.countryCode + loginInfo.phoneNumber,
				window.recaptchaVerifier
			)
				.then((confirmationResult) => {
					window.confirmationResult = confirmationResult;
				})
				.catch((error) => {
					console.log(error);
				});

			if (!loginInfo.phoneNumber) {
				navigate('/');
			}
		}
		return () => clearTimeout(setTime);
	}, []);

	useEffect(() => {
		if (timeLeft < 30000) {
			setIsVerificationTimeLeft30s(true);
		} else {
			setIsVerificationTimeLeft30s(false);
		}
	}, [timeLeft]);

	useEffect(() => {
		if (requirementCheck.length === 5) {
			setAgreeState(true);
		}
	}, [requirementCheck]);

	function onCheckBox() {
		if (agreeState) {
			setRequirementCheck([]);
			setSelectableCheck(0);
		} else {
			setRequirementCheck([0, 1, 2, 3, 4]);
			setIsRequirement(false);
			setSelectableCheck(1);
		}
		setAgreeState(!agreeState);
	}

	function onVerificationInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.keyCode === 13) {
			onNextBtn();
		}
	}

	async function onNextBtn() {
		if (isVerified && !agreeState) {
			setIsAgreeSignup(false);
		} else if (isVerified && isAgeCheck) {
			const res = await axios.post('/auth/join', {
				phoneNumber: loginInfo.phoneNumber,
				loginType: 'phoneNumber',
				marketingAgree: selectableCheck,
			});

			if (res.status === 200) {
				navigate(routerConstant.SIGNUP_WRITEINFO);
			}
		}
	}

	function onAgeCheckBox() {
		setIsAgeCheck(!isAgeCheck);
	}

	function isRequirementVisible() {
		setIsRequirement(!isRequirement);
	}

	function onReQuirementBtn(idx: number) {
		if (requirementCheck.includes(idx)) {
			const filterArr = requirementCheck.filter((element) => element !== idx);
			setRequirementCheck(filterArr);
			setAgreeState(false);
		} else {
			setRequirementCheck((prev) => [...prev, idx]);
		}
	}

	function onVerificationInputChange({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) {
		if (value.length < 6 && !isVerificationCheck) {
			setIsVerificationCheck(true);
		}
		if (value.length <= 6) {
			setVerificationValue(value);
		}
	}

	function onResetVerification() {
		start();
		setIsLoading(true);
		signInWithPhoneNumber(
			auth,
			loginInfo.countryCode + loginInfo.phoneNumber,
			window.recaptchaVerifier
		)
			.then((confirmationResult) => {
				window.confirmationResult = confirmationResult;
				setIsLoading(false);
			})
			.catch(() => {});
		setIsAgreeSignup(true);
		setIsVerificationCheck(true);
	}

	useEffect(() => {
		if (timeLeft !== 0 && verificationValue?.toString()?.length === 6) {
			window.confirmationResult
				.confirm(verificationValue)
				.then(async () => {
					setIsVerified(true);
					setTimeout(() => {
						setIsCheckListVisible(true);
					}, 200);
				})
				.catch(() => {
					setIsVerificationCheck(false);
				});
		} else {
			setIsVerified(false);
		}
	}, [verificationValue]);

	let phoneNumber = '';
	if (loginInfo.countryCode === '+82') {
		phoneNumber = loginInfo.phoneNumber
			.replace(/[^0-9]/g, '')
			.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
			.replace(/(\-{1,2})$/g, '');
	} else {
		phoneNumber = loginInfo.phoneNumber;
	}

	const onClickResetVerificationBtn = () => {
		onResetVerification();
		setIsInqueryModalOpen(false);
	};

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<ThreeBtnModal
				isModalVisible={isInqueryModalOpen}
				bottomText='인증번호는 해외(Google)에서 발송돼요. 메세지가 스팸함으로 도착하지 않았는지 확인해주세요.'
				bottomText2='로그인에 어려움이 있으신 경우 채널톡으로 문의해 주세요.'
				topBtnColor='none'
				topBtnText='인증번호 다시 받기'
				topBtnClick={onClickResetVerificationBtn}
				leftBtnText='닫기'
				leftBtnClick={() => {
					setIsInqueryModalOpen(false);
				}}
				rightBtnColor='blue'
				rightBtnText='문의하기'
				rightBtnClick={() => {
					setIsInqueryModalOpen(false);
					const btn: HTMLElement = document.getElementsByClassName(
						'Launcherstyled__Wrapper-ch-front__sc-oef45p-0 gzVfHp'
					)[0] as HTMLElement;
					btn?.click();
					const btnm: HTMLElement = document.getElementsByClassName(
						'Launcherstyled__Wrapper-ch-front__sc-oef45p-0 jQPSEh'
					)[0] as HTMLElement;
					btnm?.click();
				}}
			/>
			<HeaderBar loginPage={true} />
			<p className='mainText'>휴대폰으로 발송된 인증번호를 입력해주세요</p>
			<p className='subText'>최대 1분 정도 소요될 수 있어요. 잠시만 기다려주세요.</p>
			<InfoContainer isLeft30s={isVerificationTimeLeft30s}>
				<p>휴대폰 번호</p>
				<p className='phoneNum'>{phoneNumber}</p>
				{!isVerified && (
					<p className='count'>
						남은 인증 시간: {moment(timeLeft).format('mm')}분{' '}
						{moment(timeLeft).format('ss')}초
					</p>
				)}
			</InfoContainer>

			<CertificateContainer>
				<SignupInput
					readOnly={isVerified}
					autofocus={true}
					type='number'
					label='인증번호'
					placeHolder='인증번호 6자리를 입력해 주세요.'
					icon={
						<VerificationCheckIcon
							check={isVerificationCheck}
							isVerified={isVerified}
						/>
					}
					value={verificationValue}
					onChange={onVerificationInputChange}
					onKeyDown={onVerificationInputKeyDown}
					check={isAgreeSignup && isVerificationCheck}
					checkText='올바른 인증번호를 입력해주세요.'
				/>
				{/* <PrimaryBtn
					label='인증번호 다시 받기'
					color='none'
					onClick={onResetVerification}
				/> */}
				{!isVerified && (
					<NoticeCard
						text='인증번호가 도착하지 않나요?'
						onClick={() => {
							setIsInqueryModalOpen(true);
						}}
						fontBold={true}
						color='blue'
					/>
				)}
			</CertificateContainer>
			<CheckContainer isVisible={isCheckListVisible}>
				<SingleCheck
					isCheck={isAgeCheck}
					onClick={onAgeCheckBox}
					isAgreeCheck={isAgreeSignup}
					label={'만 14세 이상임을 확인합니다.'}
				/>
				<AllAgreeContainer isDisplay={isRequirement}>
					<div>
						{agreeState ? (
							<IsCheckBox onClick={onCheckBox} />
						) : (
							<IsNotCheckBox
								onClick={onCheckBox}
								agree={isAgreeSignup}
							/>
						)}
						회원가입을 위한 필수 이용약관에 동의합니다.
					</div>
					<span onClick={isRequirementVisible}>
						{isRequirement ? <ArrowUp /> : <ArrowDown />}
					</span>
				</AllAgreeContainer>
				<FooterContainer isDisplay={isRequirement}>
					<RequirementItem
						label='피플스헬스 서비스 이용약관(필수)'
						check={requirementCheck.includes(0)}
						isAgree={requirementCheck.includes(0) ? true : isAgreeSignup}
						link='https://www.knowingdoctor.com/terms?tab=service'
						onClick={() => onReQuirementBtn(0)}
					/>
					<RequirementItem
						label='개인정보처리방침(필수)'
						check={requirementCheck.includes(1)}
						onClick={() => onReQuirementBtn(1)}
						isAgree={requirementCheck.includes(1) ? true : isAgreeSignup}
						link='https://www.knowingdoctor.com/terms?tab=privacy'
					/>
					<RequirementItem
						label='개인정보 수집 및 이용 동의(필수)'
						check={requirementCheck.includes(2)}
						isAgree={requirementCheck.includes(2) ? true : isAgreeSignup}
						onClick={() => onReQuirementBtn(2)}
						link='https://www.knowingdoctor.com/terms?tab=infocollection'
					/>
					<RequirementItem
						label='개인(민감)정보 수집 및 이용 동의(필수)'
						check={requirementCheck.includes(3)}
						isAgree={requirementCheck.includes(3) ? true : isAgreeSignup}
						onClick={() => onReQuirementBtn(3)}
						link='https://www.knowingdoctor.com/terms?tab=sensitiveinfo'
					/>
					<RequirementItem
						label='제3자 개인(민감)정보 제공 동의(필수)'
						check={requirementCheck.includes(4)}
						isAgree={requirementCheck.includes(4) ? true : isAgreeSignup}
						onClick={() => onReQuirementBtn(4)}
						link='https://www.knowingdoctor.com/terms?tab=provision'
					/>
					<RequirementItem
						label={'마케팅 정보 수신 동의(선택)'}
						check={selectableCheck ? true : false}
						isAgree={true}
						onClick={() =>
							setSelectableCheck((prev: number) => (prev === 0 ? 1 : 0))
						}
						link='https://www.knowingdoctor.com/terms?tab=marketing'
					/>
				</FooterContainer>
				<PrimaryBtn label='회원가입하기' color='blue' onClick={onNextBtn} />
			</CheckContainer>
			<div ref={captchaRef} />
		</Cover>
	);
}

const Cover = styled.div`
	width: ${styleConstant.DESKTOP_BODY_WIDTH};
	margin: ${styleConstant.DESKTOP_BODY_MARGIN};
	text-align: start;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.moreLarge};
		line-height: 40px;
		font-weight: bold;
		margin-top: 24px;
		margin-bottom: 10px;
		word-break: keep-all;
	}
	.passwordSubText {
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		color: #969696;
		margin: 8px 0 16px;
	}
`;

const InfoContainer = styled.div<{ isLeft30s: boolean }>`
	width: 100%;
	height: auto;
	padding: 16px;
	background-color: #f5f7f7;
	border: none;
	border-radius: 12px;
	transition: all 0.5s;
	p {
		font-size: ${styleConstant.FONT_SIZE.small};
		line-height: 150%;
		letter-spacing: -0.2px;
		margin-bottom: 8px;
		color: #505759;
	}
	.phoneNum {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		line-height: 150%;
		color: #3f4443;
	}
	.count {
		margin: 0;
		color: ${({ isLeft30s }) =>
			isLeft30s ? styleConstant.COLOR.DESTRUCTIVE.DEFAULT : styleConstant.COLOR.Black};
	}
`;

const CertificateContainer = styled.div`
	margin: 24px 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const VerificationCheckIcon = styled(CheckCircleOutlined)<{ check: boolean; isVerified: boolean }>`
	color: ${({ check, isVerified }) =>
		check
			? isVerified
				? styleConstant.COLOR.CTA.DEFAULT
				: ''
			: styleConstant.COLOR.DESTRUCTIVE.DEFAULT};
	font-size: 25px;
	line-height: 38px;
	margin: 3px 0 0 0;
`;
const CheckContainer = styled.div<{ isVisible: boolean }>`
	opacity: ${({ isVisible }) => (isVisible ? '100%' : '0%')};
	transition: all 0.5s;
`;

const AllAgreeContainer = styled.div<{ isDisplay: true | false }>`
	display: flex;
	font-size: ${styleConstant.FONT_SIZE.small};
	font-weight: bold;
	letter-spacing: -0.18px;
	align-items: center;
	margin-top: 16px;
	margin-bottom: ${({ isDisplay }) => (isDisplay ? '0' : '24px')};
	word-break: keep-all;
	justify-content: space-between;
	div {
		display: flex;
		gap: 8px;
	}
	svg {
		cursor: pointer;
	}
`;

const IsNotCheckBox = styled.div<{ agree: true | false }>`
	width: 20px;
	height: 20px;
	border: ${({ agree }) => (agree ? 'solid 1px #cfcfcf' : 'solid 1px #FFBF3F')};
	border-radius: 6px;
	cursor: pointer;
`;

const IsCheckBox = styled(CheckBoxIcon)``;

const FooterContainer = styled.div<{ isDisplay: true | false }>`
	width: 100%;
	background-color: #f5f7f7;
	border-radius: 12px;
	padding: 12px 16px;
	display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
	flex-direction: column;
	gap: 8px;
	margin: 12px 0 24px;
`;
