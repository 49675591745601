import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { HeaderBar } from 'components/header';
import { PrimaryInput, BtnInput, PrimaryTextarea } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { PersonalBrandingModal, LoadingModal } from 'components/modal';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import routerConstant from 'data/constant/routerConstant';
import tokenConstant from 'data/constant/tokenConstant';

export default function PersonalBrandingPage() {
	const navigate = useNavigate();
	const axios = useAxios();
	const profileInputRef = useRef<any>(null);

	const [name, setName] = useState<string>('');
	const [part, setPart] = useState<string>('');
	const [department, setDepartment] = useState<string>('');
	const [profileImg, setProfileImg] = useState<any>('');
	const [s3Img, setS3Img] = useState<string>('');
	const [career, setCareer] = useState<string>('');
	const [introduce, setIntroduce] = useState<string>('');
	const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
	const doctorId = localStorage.getItem(tokenConstant.doctorId);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get(`/doctor/${doctorId}`);
			setName(res.data.name);
			setPart(res.data.belongTo);
			setDepartment(res.data.department);
			if (res.data.profileImg) {
				setProfileImg({ name: '***.png' });
				setS3Img(res.data.profileImg);
			}
			setCareer(res.data.experience);
			setIntroduce(res.data.introduce);
			setIsLoading(false);
		})();
	}, [doctorId]);

	function onNameInput(e: any) {
		setName(e.target.value);
	}

	function onPartInput(e: any) {
		setPart(e.target.value);
	}

	function onDepartmentInput(e: any) {
		setDepartment(e.target.value);
	}

	function onProfileImgBtn(e: any) {
		e.preventDefault();
		profileInputRef.current.click();
	}

	async function onProfileImgInput(e: any) {
		if (e.target.files[0]) {
			const formData = new FormData();
			formData.append('image', e.target.files[0]);
			const res = await axios.post(`/image?type=images`, formData);
			setS3Img(res.data.src);
			setProfileImg(e.target.files[0]);
		}
	}

	function onCareerInput(e: any) {
		setCareer(e.target.value);
	}

	function onIntroduceInput(e: any) {
		setIntroduce(e.target.value);
	}

	function onPreviewBtn() {
		setIsVisibleModal(true);
	}

	function onConfirmBtn() {
		setIsVisibleModal(false);
	}

	async function onSaveBtn() {
		setIsLoading(true);
		await axios.put(`/doctor/${doctorId}`, {
			name: name,
			belongTo: part,
			department: department,
			profileImg: s3Img,
			experience: career,
			introduce: introduce,
		});
		setIsLoading(false);
		navigate(0);
	}

	return (
		<Cover>
			<PersonalBrandingModal
				isModalVisible={isVisibleModal}
				name={name}
				part={part}
				department={department}
				profileImg={s3Img}
				career={career}
				introduce={introduce}
				btnText='확인'
				onBtnClick={onConfirmBtn}
			/>
			<LoadingModal isModalVisible={isLoading} />
			<HeaderBar noBack={true} nowPath={menuConstant.PERSONAL_BRANDING} />
			<p className='mainText'>퍼스널 브랜딩</p>
			<Info>
				<SmallInfo>
					<PrimaryInput label='이름' value={name} onChange={onNameInput} />
					<PrimaryInput label='소속' value={part} onChange={onPartInput} />
					<PrimaryInput
						label='진료과목'
						value={department}
						onChange={onDepartmentInput}
					/>
					<BtnInput
						label='프로필 사진'
						type='file'
						value={profileImg.name}
						inputRef={profileInputRef}
						onChange={onProfileImgInput}
						btnLabel='이미지 찾기'
						onClick={onProfileImgBtn}
					/>
					<PrimaryTextarea
						label='경력'
						value={career}
						onChange={onCareerInput}
						height='312'
					/>
				</SmallInfo>
				<TextInfo>
					<PrimaryTextarea
						label='소개'
						value={introduce}
						onChange={onIntroduceInput}
						height='600'
					/>
					<BtnContainer>
						<PrimaryBtn label='저장하기' onClick={onSaveBtn} />
						<PrimaryBtn
							label='미리보기'
							color='none'
							onClick={onPreviewBtn}
						/>
					</BtnContainer>
				</TextInfo>
			</Info>
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	.mainText {
		font-size: 40px;
		font-weight: bold;
		margin-top: 48px;
	}
`;

const Info = styled.div`
	display: flex;
`;

const SmallInfo = styled.div`
	width: 50%;
	padding-right: 16px;
`;

const TextInfo = styled.div`
	width: 50%;
	padding-left: 16px;
`;

const BtnContainer = styled.div`
	display: flex;
	gap: 16px;
`;
