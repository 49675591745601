import React, { useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderBar } from 'components/header';
import { ErrorAlert } from 'components/alert';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import { RootState } from 'store/reducer/rootReducer';
import routerConstant from 'data/constant/routerConstant';

export default function WriteDoctorInfoPage() {
	const navigate = useNavigate();
	const axios = useAxios();

	const [name, setName] = useState<string>('');
	const [department, setDepartment] = useState<string>('');
	const [belongTo, setBelongTo] = useState<string>('');
	const [licenseNumber, setLicenseNumber] = useState<string>('');
	const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

	const phoneNumber = useSelector((state: RootState) => state.login.phoneNumber);

	function onNameInput(e: any) {
		setName(e.target.value);
	}

	function onDepartmentInput(e: any) {
		setDepartment(e.target.value);
	}

	function onBelongToInput(e: any) {
		setBelongTo(e.target.value);
	}

	function onLicenseNumberInput(e: any) {
		setLicenseNumber(e.target.value);
	}

	async function onSubmitBtn() {
		if (
			name.length >= 2 &&
			name.length < 20 &&
			department.length &&
			belongTo.length &&
			licenseNumber.length >= 5 &&
			licenseNumber.length <= 8
		) {
			await axios.post('/doctor', {
				phoneNumber: phoneNumber,
				name: name,
				licenseNumber: licenseNumber,
				belongTo: belongTo,
				department: department,
			});
			navigate(routerConstant.ASSOCITATE);
		} else {
			setIsAlertVisible(true);
			setTimeout(() => setIsAlertVisible(false), 2000);
		}
	}

	return (
		<Cover>
			<HeaderBar noBack={true} loginPage={true} />
			<ErrorAlert label='답변을 정확히 입력해주세요.' toggle={isAlertVisible} />
			<p className='mainText'>아래의 정보를 알려주세요</p>
			<InputContainer>
				<PrimaryInput label='이름' value={name} onChange={onNameInput} />
				<PrimaryInput
					label='진료과'
					value={department}
					onChange={onDepartmentInput}
				/>
				<PrimaryInput label='소속' value={belongTo} onChange={onBelongToInput} />
				<PrimaryInput
					label='의사 면허번호'
					type='number'
					value={licenseNumber}
					onChange={onLicenseNumberInput}
				/>
			</InputContainer>
			<PrimaryBtn label='회원가입 완료' onClick={onSubmitBtn} />
		</Cover>
	);
}

const Cover = styled.div`
	width: ${styleConstant.DESKTOP_BODY_WIDTH};
	margin: ${styleConstant.DESKTOP_BODY_MARGIN};
	text-align: start;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.moreLarge};
		font-weight: bold;
		margin: 48px 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 0 0 48px;
`;
