import axios, { AxiosInstance } from "axios";
import { useEffect } from "react";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default function useAxios(certificateToken: string) {
  axiosInstance.interceptors.request.use((config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        "content-Type": "application/json",
        Authorization: `Bearer ${certificateToken}`,
      },
    };
  });

  return axiosInstance;
}
