/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";
import styled from "styled-components";

import styleConstant from "data/constant/styleConstant";
import { CategorySymptom } from "components/detail";

export function PUserSymptom({ userSymptom }: any) {
  const symptomCategory: any = [];
  userSymptom?.map((element: any) => {
    if (symptomCategory.length) {
      for (let i = 0; i < symptomCategory.length; i++) {
        if (symptomCategory[i][0].smallCategory === element.smallCategory) {
          symptomCategory[i].includes(element)
            ? null
            : symptomCategory[i].push(element);
          break;
        } else {
          i === symptomCategory.length - 1
            ? symptomCategory.push([element])
            : null;
        }
      }
    } else {
      symptomCategory.push([element]);
    }
  });

  return (
    <>
      {symptomCategory?.map((symptomList: any, idx: number) => (
        <Cover key={idx}>
          <Header key={idx}>
            <div>{symptomList[0].smallCategory}</div>
          </Header>
          <InfoContainer key={idx}>
            {symptomList.map((symptomQuestion: any, idx: number) =>
              symptomQuestion.answerType === "업로드" ? (
                <CategorySymptom
                  key={idx}
                  question={symptomQuestion.mainQuestion}
                  answer={symptomQuestion.UserCounselingQuestion.answer}
                  last={idx === symptomList.length - 1}
                  isImg={true}
                />
              ) : (
                <CategorySymptom
                  key={idx}
                  question={symptomQuestion.mainQuestion}
                  answer={symptomQuestion.UserCounselingQuestion.answer}
                  last={idx === symptomList.length - 1}
                />
              )
            )}
          </InfoContainer>
        </Cover>
      ))}
    </>
  );
}

const Cover = styled.div`
  margin-bottom: 48px;
  @media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
    width: 328px;
  }
`;
const Header = styled.div`
  margin: 0 0 8px 0;
  display: flex;
  justify-content: space-between;
  font-size: ${styleConstant.FONT_SIZE.small};
  font-weight: bold;
`;

const InfoContainer = styled.div`
  padding: 16px;
  margin: 0;
  background-color: #f8f8f8;
  border-radius: 20px;
  @media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
    width: 328px;
  }
`;
