import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { useDispatch } from 'react-redux';
import { login, allReset } from 'store/reducer/loginReducer';
import { HeaderBar } from 'components/header';
import { LoadingModal } from 'components/modal';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { ReactComponent as PhoneIcon } from 'assets/icon/login/phoneIcon.svg';
import { CountryCodeDropdown } from 'components/dropdown';
import styleConstant from 'data/constant/styleConstant';
import routerConstant from 'data/constant/routerConstant';
import countryCodeConstant from 'data/constant/countryCodeConstant';

export default function LoginPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const axios = useAxios();
	const dispatch = useDispatch();
	const state = location.state;
	const [selectCountryCode, setSelectCountryCode] = useState<{
		[countryCode: string]: string;
	}>({
		'대한민국 (+82)': '+82',
	});
	const [countryCodeValue, setCountryCodeValue] = useState<string>('');
	const [phoneNumView, setPhoneNumView] = useState<string>('');
	const [phoneNumValue, setPhoneNumValue] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isPhone, setIsPhone] = useState<boolean>(true);

	useEffect(() => {
		dispatch(allReset());
	}, []);

	function onPhoneValueChange({ target: { value } }: any) {
		if (Object.values(selectCountryCode)[0] === '+82') {
			const hypenValue = value
				.replace(/[^0-9]/g, '')
				.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
				.replace(/(\-{1,2})$/g, '');
			setPhoneNumView(hypenValue);
			setPhoneNumValue(hypenValue.replace(/-/gi, ''));
		} else {
			setPhoneNumView(value);
			setPhoneNumValue(value);
		}
	}

	const handleCountryCodeDropdownClick = (code: any) => {
		setSelectCountryCode(code);
	};

	const handleCountryCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCountryCodeValue(e.target.value);
	};

	function onInputEnterKey(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.keyCode === 13) {
			handleLoginBtn();
		}
	}

	const countryCodeDispatch = () => {
		Object.keys(selectCountryCode)[0] === '그 외'
			? dispatch(
					login({
						phoneNumber: phoneNumValue,
						countryCode: countryCodeValue,
					})
			  )
			: dispatch(
					login({
						phoneNumber: phoneNumValue,
						countryCode: Object.values(selectCountryCode)[0],
					})
			  );
	};

	const handleLoginBtn = async () => {
		if (phoneNumValue.length >= 10) {
			setIsLoading(true);
			const res = await axios.post('/auth/check/phone', {
				phoneNumber: phoneNumValue,
			});
			if (res.status === 200) {
				countryCodeDispatch();
				navigate(routerConstant.SIGNUP);
			} else if (res.status === 209) {
				countryCodeDispatch();
				if (state) {
					navigate(routerConstant.CERTIFICATE, { state: state });
				} else {
					navigate(routerConstant.CERTIFICATE);
				}
			} else {
				window.alert('다시 시도해주세요');
			}
			setIsLoading(false);
		} else {
			setIsPhone(false);
		}
	};

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<HeaderBar loginPage={true} noBack={true} />
			<div>
				<p className='subText'>파트너 회원을 위한 서비스입니다.</p>
				<p className='mainText'>휴대폰 번호를 입력해주세요</p>
			</div>
			<CountryCodeDropdown
				title='국가'
				menuList={countryCodeConstant}
				selectLabel={Object.keys(selectCountryCode)[0]}
				onClick={handleCountryCodeDropdownClick}
			/>
			{Object.keys(selectCountryCode)[0] === '그 외' && (
				<PrimaryInput
					type='number'
					label='국가 번호'
					value={countryCodeValue}
					onChange={handleCountryCodeInputChange}
					onKeyDown={onInputEnterKey}
				/>
			)}
			<PrimaryInput
				autofocus={true}
				type='any'
				label='휴대폰 번호'
				icon={<PhoneIcon />}
				value={phoneNumView}
				onChange={onPhoneValueChange}
				onKeyDown={onInputEnterKey}
				check={isPhone}
				checkText='휴대폰 번호를 입력해주세요'
				placeHolder={
					Object.values(selectCountryCode)[0] === '+82'
						? '예) 010-0000-0000'
						: ''
				}
			/>
			<PrimaryBtn label='다음' onClick={handleLoginBtn} />
		</Cover>
	);
}

const Cover = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: ${styleConstant.DESKTOP_BODY_WIDTH};
	margin: ${styleConstant.DESKTOP_BODY_MARGIN};
	text-align: start;
	.mainText {
		margin: 0;
		font-weight: bold;
		font-size: 28px;
		line-height: 24px;
	}
	.subText {
		margin: 48px 0 16px 0;
		font-size: ${styleConstant.FONT_SIZE.medium};
		line-height: 28px;
		font-weight: 500;
	}
`;
