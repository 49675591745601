import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckBoxIcon } from "assets/icon/login/checkBox.svg";
import { ReactComponent as TermsIcon } from "assets/icon/login/termsIcon.svg";
import styleConstant from "data/constant/styleConstant";

interface RequirementItemProps {
  label: string;
  check: boolean;
  isAgree: boolean;
  link?: string;
  onClick: (event: React.MouseEvent<any>) => void;
}

export function RequirementItem({
  label,
  check,
  link,
  isAgree,
  onClick,
}: RequirementItemProps) {
  return (
    <Cover>
      {check ? (
        <IsCheckBox onClick={onClick} />
      ) : (
        <IsNotCheckBox agree={isAgree} onClick={onClick} />
      )}
      {label}
      <a href={link} target="_blank" rel="noreferrer">
        <LinkIcon />
      </a>
    </Cover>
  );
}

const Cover = styled.div`
  display: flex;
  gap: 8px;
  font-size: ${styleConstant.FONT_SIZE.small};
  line-height: 25px;
  letter-spacing: -0.18px;
  word-break: keep-all;
  a {
    margin: 0;
    padding: 0;
    height: 20px;
  }
`;

const IsCheckBox = styled(CheckBoxIcon)`
  min-width: 20px;
  cursor: pointer;
`;

const IsNotCheckBox = styled.div<{ agree: true | false }>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: ${({ agree }) => (agree ? "solid 1px #cfcfcf" : "solid 1px #FFBF3F")};
  border-radius: 6px;
  cursor: pointer;
`;

const LinkIcon = styled(TermsIcon)`
  height: 20px;
  padding-top: 5px;
  min-width: 12px;
  cursor: pointer;
`;
