import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';

interface ThreeBtnModalProps {
	isModalVisible: boolean;
	mainText?: string;
	topText?: string;
	bottomText?: string;
	bottomText2?: string;
	topBtnText?: string;
	leftBtnText: string;
	rightBtnText: string;
	topBtnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	leftBtnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	rightBtnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	topBtnColor?: 'blue' | 'red' | 'none';
	rightBtnColor?: 'blue' | 'red' | 'none';
}

export function ThreeBtnModal({
	isModalVisible,
	mainText,
	topText,
	bottomText,
	bottomText2,
	topBtnText,
	leftBtnText,
	rightBtnText,
	topBtnClick,
	leftBtnClick,
	rightBtnClick,
	topBtnColor = 'blue',
	rightBtnColor = 'blue',
}: ThreeBtnModalProps) {
	return (
		<StyledModal
			visible={isModalVisible}
			maskClosable={false}
			centered={true}
			closable={false}
			footer={null}
			width={'328px'}
			zIndex={999999999}>
			<TextContainer>
				<p className='subText'>{topText}</p>
				<p className='mainText'>{mainText}</p>
				<p className='subText'>{bottomText}</p>
				<p className='subText2'>{bottomText2}</p>
			</TextContainer>
			<PrimaryBtn
				className='topBtn'
				label={topBtnText}
				onClick={topBtnClick}
				color={topBtnColor}
			/>
			<BtnContainer>
				<PrimaryBtn label={leftBtnText} onClick={leftBtnClick} color='none' />
				<PrimaryBtn
					label={rightBtnText}
					onClick={rightBtnClick}
					color={rightBtnColor}
				/>
			</BtnContainer>
		</StyledModal>
	);
}

const StyledModal = styled(Modal)`
	.ant-modal-body {
		padding: 24px 16px;
	}
	.ant-modal-content {
		border-radius: 16px;
		text-align: center;
	}
	.topBtn {
		margin: 10px 0 18px 0;
	}
`;

const TextContainer = styled.div`
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		line-height: ${styleConstant.LINE_HEIGHT.large};
		word-break: keep-all;
	}
	.subText {
		font-size: ${styleConstant.FONT_SIZE.medium};
	}
	.subText2 {
		font-size: ${styleConstant.FONT_SIZE.medium};
		margin: -16px 0 35px;
	}
`;

const BtnContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`;
