import React, { useEffect, useState } from 'react';
import useAxios from 'api/axiosCustom';

import styled from 'styled-components';
import HospitalSelectBox from './hospitalSelectBox';
import { useDispatch } from 'react-redux';
import { getList, selectHospital } from 'store/reducer/hospitalReducer';

export default function HospitalSelectContainer({
	selectedHospitalId,
	setSelectedHospitalId,
	hospitalList,
}: any) {
	const dispatch = useDispatch();

	useEffect(() => {
		const doctorId = localStorage.getItem('kdDId');
		dispatch(getList(doctorId));
	}, []);

	const handleHospital = (e: any) => {
		dispatch(selectHospital(e.target.id));
	};

	return (
		<HospitalContainer>
			{hospitalList?.map((hospital: any) => (
				<HospitalSelectBox
					key={hospital.id}
					hospital={hospital}
					selectedHospitalId={selectedHospitalId}
					handleHospital={handleHospital}
				/>
			))}
			{hospitalList?.length === 0 && (
				<NoHospital>
					소속된 병원이 없습니다.
					<br />
					관리자에게 문의해주세요.
				</NoHospital>
			)}
		</HospitalContainer>
	);
}

const HospitalContainer = styled.div`
	width: auto;
	height: auto;
	margin: 25px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	gap: 16px;
`;

const NoHospital = styled.div`
	font-size: 16px;
	font-weight: 400;
	color: #bec6c4;
`;
