import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHospitalList } from 'api/hospitalApi';

interface HospitalState {
	list: Array<any>;
	selectedHospitalId: string;
}

const initialState: HospitalState = {
	list: [],
	selectedHospitalId: '0',
};

const hospitalSlice = createSlice({
	name: 'hospital',
	initialState,
	reducers: {
		selectHospital: (state, action) => {
			state.selectedHospitalId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getList.fulfilled, (state, action) => {
			state.list = action.payload;
		});
	},
});

export const { selectHospital } = hospitalSlice.actions;
export default hospitalSlice.reducer;

export const getList = createAsyncThunk(
	'hospital/getList',
	async (payload: string | null, { dispatch }) => {
		const hospitalList = await getHospitalList(payload);
		return hospitalList;
	}
);
