import React from 'react';
import { AllCounseling } from './all';
import { WaitingCounseling } from './waiting';
import { ProgressCounseling } from './progress';
import { EndCounseling } from './end';
import { SearchCounseling } from './search';

interface IndexProps {
	type: string;
}

export default function Index({ type }: IndexProps) {
	switch (type) {
		case '전체':
			return <AllCounseling />;
		case '상담 대기':
			return <WaitingCounseling />;
		case '상담 중':
			return <ProgressCounseling />;
		case '상담 종료':
			return <EndCounseling />;
		// case '문진 검색':
		// 	return <SearchCounseling />;
		default:
			return <div>err</div>;
	}
}
