import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { useSelector, useDispatch } from 'react-redux';
import { addProgressCounseling } from 'store/reducer/counselingReducer';
import useObserver from 'shared/hook/useObserver';
import { LoadingModal } from 'components/modal';
import { PreDiagnosisCard } from 'components/card/preDiagnosisCard';
import { RootState } from 'store/reducer/rootReducer';
import moment from 'moment';

export function ProgressCounseling() {
	const navigate = useNavigate();
	const axios = useAxios();
	const dispatch = useDispatch();

	const counselingArr = useSelector((state: RootState) => state.counseling.progressArr);
	let hospitalId = useSelector((state: RootState) => state.hospital.selectedHospitalId);

	const [page, setPage] = useState<number>(-10);
	const [isLimit, setIsLimit] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { setRef } = useObserver(
		async (entry: any, observer) => {
			observer.observe(entry.target);
			setPage((prevState) => (prevState += 10));
		},
		{ threshold: 0.7 }
	);

	useEffect(() => {
		if (counselingArr.length === 0) {
			(async () => {
				setIsLoading(true);
				const res = await axios.get(`/counseling/doctor/${hospitalId}/progress`);
				dispatch(addProgressCounseling(res.data));
				if (res.data.length < 10) {
					setIsLimit(false);
				}
				setIsLoading(false);
			})();
		}
	}, []);

	useEffect(() => {
		if (page > 9 && isLimit) {
			(async () => {
				setIsLoading(true);
				const res = await axios.get(
					`/counseling/doctor/${hospitalId}/progress?offset=${page}`
				);
				dispatch(addProgressCounseling(res.data));
				if (res.data.length < 10) {
					setIsLimit(false);
				}
				setIsLoading(false);
			})();
		}
	}, [page]);

	function onCardClick(element: any) {
		navigate(`${element.id}`, {
			state: {
				id: element.id,
				profileId: element.ProfileId,
				hospitalId: element.hospitalId,
			},
		});
	}

	return (
		<>
			<Cover>
				<LoadingModal isModalVisible={isLoading} />
				{counselingArr.length === 0
					? null
					: counselingArr?.map((element, idx) => (
							<PreDiagnosisCard
								key={idx}
								name={element?.Profile?.name}
								gender={element?.Profile?.gender}
								birth={element?.Profile?.birthDate}
								date={moment(element.createdAt).format(
									'MM월 DD일 HH:mm'
								)}
								state={element.status}
								department={element.department}
								symptom={element.symptom}
								mainText={element.additionalSymptom}
								onClick={() => onCardClick(element)}
							/>
					  ))}
			</Cover>
			<div ref={setRef} />
		</>
	);
}

const Cover = styled.div`
	margin: 0;
	padding-bottom: 32px;
	display: flex;
	flex-wrap: wrap;
	gap: 24px 20px;
`;
