/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	FONT_SIZE: {
		moreLarge: '32px',
		large: '24px',
		medium: '16px',
		small: '14px',
		moreSmall: '12px',
	},
	BODY_PADDING: '0 16px',
	DESKTOP_SCREEN: '1024px',
	DESKTOP_BODY_WIDTH: '400px',
	DESKTOP_BODY_MARGIN: '0 auto',
	LINE_HEIGHT: {
		large: '133%',
		medium: '150%',
		small: '150%',
		moreSmall: '150%',
	},
	COLOR: {
		WHITE_BG: '#FFFFFF',
		GRAY_0: '#F5F7F7',
		GRAY_1: '#ECF0F0',
		GRAY_2: '#D9E1E2',
		GRAY_3: '#BEC6C4',
		GRAY_4: '#919D9D',
		GRAY_5: '#717C7D',
		GRAY_6: '#505759',
		GRAY_7: '#3F4443',
		GRAY_8: '#25282A',
		Black: '#000000',
		BRAND: '#2026CE',
		CTA: {
			DEFAULT: '#27B4EB',
			HOVER: '#0597CC',
			PRESSED: '#0083B1',
			DISABLED: '#72BDD7',
			SECONDARY_HOVER: '#F4FBFE',
			SECONDARY_PRESSED: '#E9F7FD',
		},
		DESTRUCTIVE: {
			DEFAULT: '#E44B55',
			HOVER: '#D72A34',
			PRESSED: '#CA222D',
			DISABLED: '#F09A9D',
			SECONDARY_HOVER: '#FEF7F7',
			SECONDARY_PRESSED: '#FCE7E7',
		},
		STATUS: {
			CAUTION: '#FFBF3F',
		},
	},
};
