import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { allReset } from 'store/reducer/counselingReducer';
import { HeaderBar } from 'components/header';
import CounselingType from 'components/counseling';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';

import { IHospital } from 'data/interface/counseling';
import HospitalSelectContainer from 'components/detail/hospitalSelectContainer';
import useAxios from 'api/axiosCustom';
import { getList, selectHospital } from 'store/reducer/hospitalReducer';
import { RootState } from 'store/reducer/rootReducer';

export default function CounselingPage() {
	const hospitalList = useSelector((state: RootState) => state.hospital.list);
	const selectedHospitalId = useSelector(
		(state: RootState) => state.hospital.selectedHospitalId
	);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location.state;

	const [menuState, setMenuState] = useState<string>('전체');
	function onMenuBtn(e: any) {
		if (menuState !== e.target.innerText.trim()) {
			dispatch(allReset());
			setMenuState(e.target.innerText.trim());
		}
	}

	useEffect(() => {
		window.setTimeout('window.location.reload()', 300000);
	}, []);

	useEffect(() => {
		if (state?.hospitalId) {
			navigate(`/counseling/${state.id}`, { state: state });
		} else if (state) {
			console.log('state exists, you should go to other page');
			navigate(`/shared/loggedin`, { state: state });
		} else {
			console.log('nothing to do.. just stay');
		}
	}, []);

	///

	useEffect(() => {
		const doctorId = localStorage.getItem('kdDId');
		dispatch(getList(doctorId));
	}, []);

	useEffect(() => {
		hospitalList[0] && dispatch(selectHospital(hospitalList[0]?.id));
	}, [hospitalList]);

	return (
		<Cover>
			<HeaderBar noBack={true} nowPath={menuConstant.COUNSELING} />
			<p className='mainText'>문진 상담</p>
			<MenuContainer>
				<span className={menuState === '전체' ? 'select' : ''} onClick={onMenuBtn}>
					전체
				</span>
				<span
					className={menuState === '상담 대기' ? 'select' : ''}
					onClick={onMenuBtn}>
					상담 대기
				</span>
				<span
					className={menuState === '상담 중' ? 'select' : ''}
					onClick={onMenuBtn}>
					상담 중
				</span>
				<span
					className={menuState === '상담 종료' ? 'select' : ''}
					onClick={onMenuBtn}>
					상담 종료
				</span>
				{/* <span
					className={menuState === '문진 검색' ? 'select' : ''}
					onClick={onMenuBtn}>
					문진 검색
				</span> */}
				<br />

				<HospitalSelectContainer
					hospitalList={hospitalList}
					selectedHospitalId={selectedHospitalId}
				/>
			</MenuContainer>

			<CounselingType type={menuState} />
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	text-align: start;
	.mainText {
		font-size: 40px;
		font-weight: bold;
		margin-top: 48px;
	}
`;

const MenuContainer = styled.div`
	margin-bottom: 20px;
	span {
		margin-right: 16px;
		font-size: ${styleConstant.FONT_SIZE.medium};
		cursor: pointer;
	}
	.select {
		padding-bottom: 4px;
		font-weight: bold;
		border-bottom: solid 2px #1bbcff;
	}
`;
