import axios, { AxiosInstance } from 'axios';
const axiosInstance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});
export async function getProfileAndCounseling(crypto: string) {
	// console.log("axios start : get profile and counseling");
	// const res_Local = await axios.get(
	//   `http://localhost:29932/v1/api/share/sharedResult/${crypto}`
	// );
	// return res_Local;
	const res = await axios.get(
		`https://api.knowingdoctor.com/v1/api/share/sharedResult/${crypto}`
	);
	return res;
}

export async function getOtherResults(crypto: string) {
	// console.log("axios start");

	// const res_Local = await axios.get(
	//   `http://localhost:29932/v1/api/share/otherResults/${crypto}`
	// );
	// return res_Local;
	const res = await axios.get(
		`https://api.knowingdoctor.com/v1/api/share/otherResults/${crypto}`
	);
	return res;
}
