import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer/rootReducer";
import tokenConstant from "data/constant/tokenConstant";

export function LoginRoute({ children }: any) {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(tokenConstant.accessToken),
  );
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.login.accessToken);

  useEffect(() => {
    setAccessToken(localStorage.getItem(tokenConstant.accessToken));
  }, [token]);

  return accessToken ? (
    location.state ? (
      // navigate("/share/loggedin", { state: location.state })
      <Navigate to="/share/loggedin" state={location.state} />
    ) : (
      <Navigate to="/counseling" />
    )
  ) : (
    children
  );
}
