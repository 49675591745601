import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

interface SignupInputProps {
	readOnly?: boolean;
	autofocus?: boolean;
	type?: string;
	label?: string;
	icon?: ReactElement<any, any>;
	value: any;
	placeHolder?: string;
	check: boolean;
	checkText: string;
	inputmode?: 'text' | 'numeric';
	pattern?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export function SignupInput({
	readOnly = false,
	autofocus = false,
	type = 'text',
	label = '',
	icon,
	value,
	placeHolder,
	check,
	checkText,
	inputmode = 'text',
	pattern = '',
	onChange,
	onKeyDown,
}: SignupInputProps) {
	const [handleFocus, setHandleFocus] = useState<boolean>(false);

	function onInputFocus() {
		setHandleFocus(!handleFocus);
	}

	const customRef = useRef<any>(null);

	useEffect(() => {
		if (autofocus && customRef.current) {
			customRef.current.focus();
		}
	}, []);

	return (
		<Cover>
			{label !== '' ? <p className='label'>{label}</p> : null}
			<InputContainer focus={handleFocus} check={check}>
				{icon}
				<Primary
					readOnly={readOnly}
					ref={customRef}
					type={type}
					value={value}
					onChange={onChange}
					onKeyDown={onKeyDown}
					onFocus={onInputFocus}
					onBlur={onInputFocus}
					placeholder={placeHolder}
					inputMode={inputmode}
					pattern={pattern}
				/>
			</InputContainer>
			<CheckText check={check}>
				<span>!</span>
				{checkText}
			</CheckText>
		</Cover>
	);
}

const Cover = styled.div`
	width: 100%;
	margin: 0;
	.label {
		font-size: ${styleConstant.FONT_SIZE.moreSmall}!important;
		color: #969696 !important;
		margin: 0 0 4px 0 !important;
	}
`;

const InputContainer = styled.div<{ focus: true | false; check: true | false }>`
	display: flex;
	align-items: center;
	width: 100%;
	height: 48px;
	padding: ${({ focus, check }) => (focus ? '7px 16px' : check ? '8px 17px' : '7px 16px')};
	border: ${({ focus, check }) =>
		focus
			? `solid 2px ${styleConstant.COLOR.CTA.DEFAULT}`
			: check
			? `solid 1px ${styleConstant.COLOR.GRAY_2}`
			: `solid 2px ${styleConstant.COLOR.STATUS.CAUTION}`};

	border-radius: 25px;
	background-color: ${styleConstant.COLOR.WHITE_BG};
	display: flex;
	line-height: 30px;
`;

const Primary = styled.input<{ readOnly: boolean }>`
	width: 75%;
	margin-left: 10px;
	border: none;
	color: ${({ readOnly }) =>
		readOnly ? styleConstant.COLOR.CTA.DEFAULT : styleConstant.COLOR.Black};
	font-size: ${styleConstant.FONT_SIZE.medium};
	line-height: ${styleConstant.LINE_HEIGHT.medium};
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&:focus {
		outline: none;
	}
	padding-bottom: 4px;
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-overflow-scrolling: touch) {
			padding: 0;
			position: relative;
			top: -1px;
		}
	}
`;

const CheckText = styled.p<{ check: true | false }>`
	display: ${({ check }) => (check ? 'none' : 'flex')};
	font-weight: 500 !important;
	font-size: ${styleConstant.FONT_SIZE.moreSmall} !important;
	color: ${styleConstant.COLOR.GRAY_8} !important;
	margin-top: 12px !important;
	margin-bottom: 0;
	span {
		width: 16px;
		height: 16px;
		color: ${styleConstant.COLOR.WHITE_BG};
		text-align: center;
		padding-left: 1px;
		background-color: ${styleConstant.COLOR.STATUS.CAUTION};
		border-radius: 8px;
		line-height: 17px;
		line-height: ${styleConstant.LINE_HEIGHT.moreSmall};
		margin-right: 8px;
	}
`;
