import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useDispatch, useSelector } from 'react-redux';
import { addAllCounseling, searchCounseling } from 'store/reducer/counselingReducer';
import { LoadingModal } from 'components/modal';
import { SearchInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import { useDebounce } from 'hooks/useDebounce';
import { RootState } from 'store/reducer/rootReducer';

interface SearchInputContainerProps {
	page: number;
	setPage?: any;
	isLimit?: boolean;
	setIsLimit?: any;
	name?: string;
	setName?: any;
	birth?: string;
	setBirth?: any;
	onNameInput?: any;
	onBirthInput?: any;
}

export function SearchInputContainer({
	page,
	setPage,
	isLimit,
	setIsLimit,
	name,
	setName,
	birth,
	setBirth,
	onNameInput,
	onBirthInput,
}: SearchInputContainerProps) {
	const axios = useAxios();
	const dispatch = useDispatch();
	let hospitalId = useSelector((state: RootState) => state.hospital.selectedHospitalId);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (birth?.length === 8) {
			setBirth(birth.replace(/-/g, '').replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
		}
	}, [birth]);

	// useEffect(() => {
	// 	if (page > 9 && isLimit) {
	// 		(async () => {
	// 			setIsLoading(true);
	// 			const res = await axios.get(
	// 				`/counseling/doctor/${hospitalId}/search?offset=${page}&name=${name}&birthDate=${birth}`
	// 			);
	// 			dispatch(addSearchCounseling(res.data));
	// 			// if (res.data.length < 10) {
	// 			// 	setIsLimit(false);
	// 			// } else {
	// 			// 	setIsLimit(true);
	// 			// }
	// 			setIsLoading(false);
	// 		})();
	// 	}
	// }, [page]);

	function onNameInputCancel() {
		setName('');
	}

	function onBirthInputCancel() {
		setBirth('');
	}

	async function onSearchBtn() {
		setIsLoading(true);
		const res = await axios.get(
			`/counseling/doctor/${hospitalId}/search?name=${name}&birthDate=${birth}`
		);
		setPage(0);
		dispatch(searchCounseling(res.data));
		if (res.data.length < 10) {
			setIsLimit(false);
		}
		setIsLoading(false);
	}

	return (
		<SearchContainer>
			<LoadingModal isModalVisible={isLoading} />
			<p className='searchMainText'>검색하실 사용자의 정보를 입력해주세요.</p>
			<InputContainer>
				<div className='input'>
					<SearchInput
						label='이름'
						placeHolder='이름을 입력해주세요'
						value={name}
						onChange={onNameInput}
						onCancel={onNameInputCancel}
					/>
				</div>
				<div className='input'>
					<SearchInput
						label='생년월일'
						placeHolder='생년월일을 입력해주세요.'
						value={birth}
						onChange={onBirthInput}
						onCancel={onBirthInputCancel}
					/>
				</div>
			</InputContainer>
		</SearchContainer>
	);
}

const SearchContainer = styled.div`
	width: 100%;
	height: 188px;
	box-sizing: border-box;
	padding: 24px 24px 32px;
	margin-bottom: 15px;
	border-radius: 20px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
	.searchMainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	gap: 20px;
	//justify-content: space-between;
	margin-top: 24px;
	align-items: center;
	.input {
		width: 50%;
		margin: 0;
		padding: 0;
	}
	.btn {
		width: 8%;
		margin: 0;
		padding-top: 24px;
	}
`;
