const countryCodeConstant: { [country: string]: string }[] = [
  { "미국 (+1)": "+1" },
  { "캐나다 (+1)": "+1" },
  { "프랑스 (+33)": "+33" },
  { "스페인 (+34)": "+34" },
  { "영국 (+44)": "+44" },
  { "독일 (+49)": "+49" },
  { "호주 (+61)": "+61" },
  { "필리핀 (+63)": "+63" },
  { "뉴질랜드 (+64)": "+64" },
  { "싱가포르 (+65)": "+65" },
  { "태국 (+66)": "+66" },
  { "일본 (+81)": "+81" },
  { "대한민국 (+82)": "+82" },
  { "베트남 (+84)": "+84" },
  { "중국 (+86)": "+86" },
  { "홍콩 (+852)": "+852" },
  { "대만 (+886)": "+886" },
  { "러시아 (+7)": "+7" },
  { "인도 (+91)": "+91" },
  { "아랍에미리트 (+971)": "+971" },
  { "그 외": "" },
];

export default countryCodeConstant;
