import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import certificateAxios from 'api/certificateAxios';
import { authConfig } from 'lib/firebase/authConfig';
import useCountDown from 'react-countdown-hook';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addToken } from 'store/reducer/loginReducer';
import { HeaderBar } from 'components/header';
import { LoadingModal, TwoBtnModal } from 'components/modal';
import { ErrorAlert } from 'components/alert';
import { SignupInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { CheckCircleOutlined } from '@ant-design/icons';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import styleConstant from 'data/constant/styleConstant';
import routerConstant from 'data/constant/routerConstant';
import { RootState } from 'store/reducer/rootReducer';
import { NoticeCard } from 'components/signup/noticeCard';
import { ThreeBtnModal } from 'components/modal/threeBtnModal';

export default function CertificatePage() {
	const navigate = useNavigate();
	const axios = useAxios();
	const dispatch = useDispatch();
	const auth = authConfig('login');
	const loginInfo = useSelector((state: RootState) => state.login);
	const location = useLocation();
	const state = location.state;
	const captchaRef = useRef<any>(null);
	const [timeLeft, { start }] = useCountDown(180 * 1000, 1000);
	const [certificateToken, setCertificateToken] = useState<string>('');
	const [isVerificationCheck, setIsVerificationCheck] = useState<boolean>(true);
	const [isVerificationTimeLeft30s, setIsVerificationTimeLeft30s] = useState<boolean>(false);
	const [verificationValue, setVerificationValue] = useState<string>('');
	const [isAgreeSignup, setIsAgreeSignup] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
	const [isInqueryModalOpen, setIsInqueryModalOpen] = useState<boolean>(false);
	const [isVerified, setIsVerified] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		const setTime = setTimeout(() => {
			setIsLoading(false);
		}, 1500);
		(async () => {
			if (typeof window !== 'undefined') {
				start();
				const res = await axios.post('/auth/certificate/phone', {
					phoneNumber: loginInfo.phoneNumber,
				});
				setCertificateToken(res.data.certificateToken);
				window.recaptchaVerifier = new RecaptchaVerifier(
					captchaRef.current,
					{
						size: 'invisible',
						callback: () => {},
					},
					auth
				);
				signInWithPhoneNumber(
					auth,
					loginInfo.countryCode + loginInfo.phoneNumber,
					window.recaptchaVerifier
				)
					.then((confirmationResult) => {
						window.confirmationResult = confirmationResult;
					})
					.catch(() => {});
			}
		})();
		return () => clearTimeout(setTime);
	}, []);

	const onResetVerification = async () => {
		start();
		setIsLoading(true);
		const res = await axios.post('/auth/certificate/phone', {
			phoneNumber: loginInfo.phoneNumber,
		});
		setCertificateToken(res.data.certificateToken);
		signInWithPhoneNumber(
			auth,
			loginInfo.countryCode + loginInfo.phoneNumber,
			window.recaptchaVerifier
		)
			.then((confirmationResult) => {
				window.confirmationResult = confirmationResult;
				setIsLoading(false);
			})
			.catch(() => {});
		setVerificationValue('');
		setIsAgreeSignup(true);
		setIsVerificationCheck(true);
	};

	function onVerificationInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.keyCode === 13) {
			onSubmitBtn();
		}
	}

	function onSubmitBtn() {
		setIsLoading(true);
		window.confirmationResult
			.confirm(verificationValue)
			.then(async () => {
				setIsVerified(true);
				const res = await certificateAxios(certificateToken).post('/doctor/login', {
					phoneNumber: loginInfo.phoneNumber,
				});
				if (res.status === 200) {
					dispatch(
						addToken({
							accessToken: res.data.accessToken,
							refreshToken: res.data.refreshToken,
							doctorId: res.data.Doctor.id,
						})
					);
					if (state) {
						navigate(routerConstant.COUNSELING, { state: state });
					} else {
						navigate(routerConstant.COUNSELING);
					}
				} else if (res.status === 211) {
					navigate(routerConstant.SIGNUP_WRITEINFO);
				} else {
					setIsLoading(false);
					navigate(routerConstant.ASSOCITATE);
				}
			})
			.catch((err: any) => {
				console.log(err);
				setIsVerificationCheck(false);
			});
		setIsLoading(false);
	}

	const onVerificationInputChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) => {
		if (value.length < 6 && !isVerificationCheck) {
			setIsVerificationCheck(true);
		}
		if (value.length <= 6) {
			setVerificationValue(value);
		}
	};

	useEffect(() => {
		if (timeLeft < 30000) {
			setIsVerificationTimeLeft30s(true);
		} else {
			setIsVerificationTimeLeft30s(false);
		}
	}, [timeLeft]);

	useEffect(() => {
		if (timeLeft !== 0 && verificationValue?.toString()?.length === 6) {
			onSubmitBtn();
		} else {
			setIsVerified(false);
		}
	}, [verificationValue]);

	let phoneNumber = '';
	if (loginInfo.countryCode === '+82') {
		phoneNumber = loginInfo.phoneNumber
			.replace(/[^0-9]/g, '')
			.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
			.replace(/(\-{1,2})$/g, '');
	} else {
		phoneNumber = loginInfo.phoneNumber;
	}

	const onClickResetVerificationBtn = () => {
		onResetVerification();
		setIsInqueryModalOpen(false);
	};

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<ThreeBtnModal
				isModalVisible={isInqueryModalOpen}
				bottomText='인증번호는 해외(Google)에서 발송돼요. 메세지가 스팸함으로 도착하지 않았는지 확인해주세요.'
				bottomText2='로그인에 어려움이 있으신 경우 채널톡으로 문의해 주세요.'
				topBtnColor='none'
				topBtnText='인증번호 다시 받기'
				topBtnClick={onClickResetVerificationBtn}
				leftBtnText='닫기'
				leftBtnClick={() => {
					setIsInqueryModalOpen(false);
				}}
				rightBtnColor='blue'
				rightBtnText='문의하기'
				rightBtnClick={() => {
					setIsInqueryModalOpen(false);
					const btn: HTMLElement = document.getElementsByClassName(
						'Launcherstyled__Wrapper-ch-front__sc-oef45p-0 gzVfHp'
					)[0] as HTMLElement;
					btn?.click();
					const btnm: HTMLElement = document.getElementsByClassName(
						'Launcherstyled__Wrapper-ch-front__sc-oef45p-0 jQPSEh'
					)[0] as HTMLElement;
					btnm?.click();
				}}
			/>

			<HeaderBar loginPage={true} />
			<ErrorAlert label='승인을 기다려주세요.' toggle={isAlertVisible} />
			<p className='mainText'>휴대폰으로 발송된 인증번호를 입력해주세요</p>
			<p className='subText'>최대 1분 정도 소요될 수 있어요. 잠시만 기다려주세요.</p>
			<InfoContainer isLeft30s={isVerificationTimeLeft30s}>
				<p>휴대폰 번호</p>
				<p className='phoneNum'>{phoneNumber}</p>
				<p className='count'>
					남은 인증 시간: {moment(timeLeft).format('mm')}분{' '}
					{moment(timeLeft).format('ss')}초
				</p>
			</InfoContainer>
			<CertificateContainer>
				<SignupInput
					readOnly={isVerified}
					autofocus={true}
					type='number'
					label='인증번호'
					placeHolder='인증번호 6자리를 입력해 주세요.'
					icon={
						<VerificationCheckIcon
							check={isVerificationCheck}
							isFilled={isVerified}
						/>
					}
					value={verificationValue}
					onChange={onVerificationInputChange}
					onKeyDown={onVerificationInputKeyDown}
					check={isAgreeSignup && isVerificationCheck}
					checkText='올바른 인증번호를 입력해주세요.'
				/>
				<NoticeCard
					text='인증번호가 도착하지 않나요?'
					onClick={() => {
						setIsInqueryModalOpen(true);
					}}
					fontBold={true}
					color='blue'
				/>
				{/* <PrimaryBtn
					label='인증번호 다시 받기'
					color='none'
					onClick={onResetVerification}
				/> */}
			</CertificateContainer>
			<div id='recaptcha-container' ref={captchaRef} />
			{/* <PrimaryBtn label='인증 완료' onClick={onSubmitBtn} /> */}
		</Cover>
	);
}

const Cover = styled.div`
	position: relative;
	width: ${styleConstant.DESKTOP_BODY_WIDTH};
	margin: ${styleConstant.DESKTOP_BODY_MARGIN};
	text-align: start;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.moreLarge};
		font-weight: bold;
		margin-top: 24px;
		margin-bottom: 0;
	}
	.subText {
		font-size: ${styleConstant.FONT_SIZE.small};
		line-height: 150%;
		letter-spacing: -0.2px;
		color: #505759;
		margin: 8px 0 24px;
		word-break: keep-all;
	}
	#recaptcha-container {
		margin: 0 0 48px;
	}
`;

const CertificateContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 24px 0 0;
`;

const InfoContainer = styled.div<{ isLeft30s: boolean }>`
	width: 100%;
	height: 112px;
	padding: 16px;
	background-color: #f5f7f7;
	border: none;
	border-radius: 12px;
	p {
		font-size: ${styleConstant.FONT_SIZE.small};
		line-height: 133%;
		letter-spacing: -0.2px;
		margin-bottom: 8px;
		color: #505759;
	}
	.phoneNum {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		line-height: 133%;
		color: #3f4443;
	}
	.count {
		margin: 0;
		color: ${({ isLeft30s }) =>
			isLeft30s ? styleConstant.COLOR.DESTRUCTIVE.DEFAULT : styleConstant.COLOR.Black};
}
	}
`;

const VerificationCheckIcon = styled(CheckCircleOutlined)<{ check: boolean; isFilled: boolean }>`
	color: ${({ check, isFilled }) =>
		check
			? isFilled
				? styleConstant.COLOR.CTA.DEFAULT
				: ''
			: styleConstant.COLOR.DESTRUCTIVE.DEFAULT};
	font-size: 25px;
	line-height: 38px;
	margin: 3px 0 0 0;
`;
