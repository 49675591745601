import React from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

interface BtnInputProps {
	label: string;
	value: string;
	placeholder?: string;
	inputRef?: React.MutableRefObject<any>;
	type?: 'text' | 'file';
	btnLabel: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function BtnInput({
	label,
	value,
	placeholder,
	inputRef,
	type = 'text',
	btnLabel,
	onChange,
	onClick,
}: BtnInputProps) {
	return (
		<Cover>
			{label !== '' ? <p className='label'>{label}</p> : null}
			<InputContainer>
				<Input
					//   value={value}
					ref={inputRef}
					accept='image/*'
					placeholder={placeholder}
					onChange={onChange}
					type={type}
				/>
				<div className='value'>{value}</div>
				<button onClick={onClick}>{btnLabel}</button>
			</InputContainer>
		</Cover>
	);
}

const Cover = styled.div`
	box-sizing: border-box;
	width: 100%;
	margin: 16px 0;
	.label {
		text-align: start;
		margin: 0 0 8px;
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		line-height: ${styleConstant.LINE_HEIGHT.moreSmall};
		color: ${styleConstant.COLOR.GRAY_7};
		font-weight: 500;
	}
`;

const InputContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	height: 48px;
	border: solid 1px #cfcfcf;
	border-radius: 25px;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	button {
		width: 20%;
		height: 38px;
		margin: 0 4px 0 0;
		border-radius: 25px;
		border: none;
		background-color: #1bbcff;
		color: #fff;
		font-size: ${styleConstant.FONT_SIZE.small};
		font-weight: bold;
		cursor: pointer;
	}
	.value {
		box-sizing: border-box;
		border: none;
		border-radius: 20px;
		width: 75%;
		height: 40px;
		padding: 10px 12px 10px 24px;
		font-size: ${styleConstant.FONT_SIZE.medium};
		line-height: 18px;
		overflow: auto;
	}
`;

const Input = styled.input`
	/* box-sizing: border-box;
  border: none;
  border-radius: 20px;
  width: 75%;
  height: 40px;
  padding: 10px 12px;
  font-size: ${styleConstant.FONT_SIZE.medium};
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  resize: none;

  &:focus {
    outline: none;
  } */
	display: none;
`;
