import styleConstant from 'data/constant/styleConstant';
import React from 'react';
import styled from 'styled-components';
interface UserInfoProps {
	name?: string;
	birth?: string;
	gender?: string;
	job?: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function PUserInfo({ name, birth, gender, job, onClick }: UserInfoProps) {
	const genderSwitch = (genderNum: number) => {
		switch (genderNum) {
			case 1:
				return '남자';
			case 2:
				return '여자';
			default:
				return '둘 다 아니에요';
		}
	};
	return (
		<Cover>
			<Header>
				<div>{'상담자 정보'}</div>
				{/* <div className="modify" onClick={onClick}>
          {수정"하기"}
        </div> */}
			</Header>
			<InfoContainer>
				<p>{'이름'}</p>
				<p className='info'>{name}</p>
				<section className='container'>
					<div>
						<p>{'나이'}</p>
						<p className='info'>{birth}</p>
					</div>
					<div>
						<p>{'성별'}</p>
						<p className='info'>{genderSwitch(Number(gender))}</p>
					</div>
				</section>
				<p>{'직업'}</p>
				<p className='info last'>{job}</p>
			</InfoContainer>
		</Cover>
	);
}

const Cover = styled.div`
	width: 100%;
	@media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
		width: 328px;
	}
`;

const Header = styled.section`
	display: flex;
	justify-content: space-between;
	margin: 0;
	font-size: ${styleConstant.FONT_SIZE.small};
	letter-spacing: -0.2px;
	font-weight: bold;
	.modify {
		color: #1bbcff;
		cursor: pointer;
	}
`;

const InfoContainer = styled.div`
	width: 100%;
	background-color: #f8f8f8;
	padding: 16px;
	margin-top: 8px;
	border-radius: 16px;
	letter-spacing: -0.2px;
	p {
		font-size: ${styleConstant.FONT_SIZE.small};
		margin: 0 0 8px;
	}
	.info {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0 0 24px;
	}
	.last {
		margin: 0;
	}
	.container {
		display: flex;
		div {
			width: 50%;
		}
	}
`;
