import { combineReducers } from '@reduxjs/toolkit';

import loginReducer from './loginReducer';
import counselingReducer from './counselingReducer';
import chatReducer from './chatReducer';
import hospitalReducer from './hospitalReducer';

const RootReducer = combineReducers({
	login: loginReducer,
	counseling: counselingReducer,
	chat: chatReducer,
	hospital: hospitalReducer,
});

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;
