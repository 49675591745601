import React from 'react';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { ReactComponent as ArrowDown } from 'assets/icon/signup/arrowDown.svg';

interface Props {
	title?: string;
	menuList: any;
	selectLabel: string;
	onClick: any;
}

const Cover = styled.div`
	width: 100%;
	.title {
		margin: 0 0 8px;
		letter-spacing: -0.15px;
		color: #3f4443;
		font-size: 12px;
		font-weight: 500;
	}
`;

const CustonDropdown = styled(Menu)`
	max-height: 300px !important;
	overflow: auto !important;
`;

const DropdownBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 48px;
	padding: 12px 23px;
	border: 1px solid #bec6c4;
	border-radius: 25px;
	letter-spacing: -0.2px;
	color: #25282a;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
`;

const MenuItem = styled.p`
	margin: 0;
	padding: 8px 12px;
	letter-spacing: -0.2px;
	color: #25282a;
	font-size: 16px;
	font-weight: 500;
`;

export function CountryCodeDropdown({ title, menuList, selectLabel, onClick }: Props) {
	const menuItem = menuList.map((label: any, idx: number) => {
		return {
			label: <MenuItem onClick={() => onClick(label)}>{Object.keys(label)}</MenuItem>,
			key: idx,
		};
	});
	const menu = <CustonDropdown items={menuItem} />;

	return (
		<Cover>
			{title && <p className='title'>{title}</p>}
			<Dropdown overlay={menu} trigger={['click']}>
				<DropdownBox>
					{selectLabel} <ArrowDown />
				</DropdownBox>
			</Dropdown>
		</Cover>
	);
}
