import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from 'assets/img/logo-horizontal.png';
import { LoadingModal } from 'components/modal';
import { getOtherResults, getProfileAndCounseling } from 'api/shareApi';
import styleConstant from 'data/constant/styleConstant';
import { PrimaryBtn } from 'components/button';
import { PUserInfo } from 'components/share/pUserInfo';
import { SymptomInfo } from 'components/share/symptomInfo';
import { PreDiagnosisCardSimple } from 'components/share/preDiagnosisCardSimple';
import { PUserSymptom } from 'components/share/pUserSymptom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import tokenConstant from 'data/constant/tokenConstant';
import { ProfileQuestion } from 'components/detail/profileQuestion';
import { ReactComponent as EnLogo } from 'assets/img/headerLogoEn.svg';

export interface Ids {
	profile: string;
	counseling: string;
}

export interface IRecent {
	id: number;
	name: string;
	symptom: string;
	enSymptom: string;
	department: string;
	enDepartment: string;
	questionIds: number;
	additionalSymptom: string;
	isCounseling: boolean;
	isEnd: boolean;
	isHealthy: boolean;
	createdAt: string;
	updatedAt: string;
	UserId: number;
	Profile: any;
	ProfileId: number;
	SymptomId: number;
	Questions: [];
}
export interface IUserProfile {
	UserId: number;
	id: number;
	name: string;
	birthDate: string;
	gender: string;
	target: string;
	job: string;
	address: string;
	detailAddress: string;
	createdAt: string;
	updatedAt: string;
}
interface ShareErr {
	mainText?: string;
	subText?: string;
}
export default function Share() {
	const { crypto } = useParams();
	const [select, setSelect] = useState<string>('result');
	const [profileInfo, setProfileInfo] = useState<IUserProfile>();

	const [interview, setInterview] = useState<IRecent>();
	const [createdAt, setCreatedAt] = useState();
	const [preInterviewArr, setPreInterviewArr] = useState<IRecent[]>();
	const [init, setInit] = useState(false);
	// const [isLoading, setIsLoading] = useState(false);
	const [err, setErr] = useState<ShareErr>();
	const [lang, setLang] = useState<string>(document.documentElement.lang);
	const axios = useAxios();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (crypto === 'loggedin') {
			const res = afterLoggedIn(location.state.counselingId);
		} else if (crypto !== undefined) {
			const detailRes = getProfileAndCounseling(crypto);
			detailRes
				.then((res) => {
					if (res.status === 200) {
						setProfileInfo(res.data.profile);
						setInterview(res.data.counseling);
						setCreatedAt(res.data.createdAt);
						setInit(true);
						// setIsLoading(true);
					}
				})
				.catch((err) => {
					// setIsLoading(true);
					if (err.response.status === 403) {
						setErr({
							mainText: '더이상 접근할 수 없는 문진입니다',
							subText: '개인정보 보호를 위해 유효시간이 설정돼 있어요. \n문진을 확인하고 싶으시다면, 새 문진 주소를 요청해주세요.',
						});
					} else {
						setErr({
							mainText: '페이지를 찾을 수 없습니다',
							subText: '입력하신 주소가 올바른지 확인해주세요.',
						});
					}
					console.log(err);
				});
		}
	}, [crypto]);
	useEffect(() => {
		if (select === 'other' && crypto) {
			if (crypto === 'loggedin') {
			} else {
				const otherResults = getOtherResults(crypto);
				otherResults
					.then((res) => setPreInterviewArr(res.data))
					.catch(console.error);
			}
		}
	}, [select]);

	const date = new Date();

	async function afterLoggedIn(id: number) {
		if (localStorage.getItem(tokenConstant.accessToken)) {
			const counselingRes = await axios.get(`/counseling/detail/${id}`);
			if (counselingRes) {
				setInterview(counselingRes.data);
				setSelect('result');
				setInit(true);
				window.scroll(0, 0);
			} else {
				console.log('error!');
			}
			const profileRes = await axios.get(
				`/counseling/doctor/all?profileId=${location.state.profileId}`
				// `/counseling/doctor?profileId=163`
			);
			if (profileRes) {
				// setInterview(profileRes.data);
				// setSelect("result");
				// setInit(true);
				setPreInterviewArr(profileRes.data);
				setProfileInfo(profileRes?.data[0]?.Profile);
				window.scroll(0, 0);
			} else {
				console.log('error!');
			}
		}
		return 0;
	}
	async function clickCounseling(id: number) {
		if (localStorage.getItem(tokenConstant.accessToken)) {
			const counselingRes = await axios.get(`/counseling/detail/${id}`);
			if (counselingRes) {
				setInterview(counselingRes.data);
				setSelect('result');
				setInit(true);
				window.scroll(0, 0);
				return counselingRes;
			} else {
				console.log('error!');
			}
		} else {
			console.log("you don't have access token");
			const confirm = window.confirm(
				'로그인 후 이용 가능합니다. 로그인 후 이용하시겠어요?'
			);
			if (confirm) {
				navigate('/', {
					state: { counselingId: id, profileId: profileInfo?.id },
				});
			}
		}
		return 0;
	}
	const handleTranslate = () => {
		if (document.documentElement.lang === 'auto') {
			document.getElementById('english-btn')?.click();
			setLang('en');
		} else if (document.documentElement.lang === 'en') {
			document.getElementById('korean-btn')?.click();
			document.getElementById('english-btn')?.click();
			setLang('ko');
		}
	};

	return (
		<>
			{err ? (
				<>
					<Header>
						<Logo src={logo} onClick={() => navigate('/')} />
					</Header>
					<Content>
						<MainText>{err?.mainText}</MainText>
						<SubText>{err?.subText}</SubText>
						<div
							style={{
								width: 'max-content',
								minWidth: '200px',
								margin: 'auto',
								marginTop: '40px',
							}}>
							<PrimaryBtn
								label='홈으로 가기'
								onClick={() => navigate('/')}
								color='blue'
							/>
						</div>
					</Content>
				</>
			) : init ? (
				<>
					<Header>
						{lang !== 'en' ? (
							<Logo src={logo} onClick={() => navigate('/')} />
						) : (
							<EnLogo
								style={{ margin: '17px 0' }}
								onClick={() => navigate('/')}
							/>
						)}
					</Header>

					<Cover>
						<SelectContainer>
							<div
								className={select === 'result' ? 'focus' : ''}
								onClick={() => setSelect('result')}>
								문진 결과
							</div>
							<div
								className={select === 'other' ? 'focus' : ''}
								onClick={() => setSelect('other')}>
								다른 문진 기록
							</div>

							<div
								onClick={handleTranslate}
								style={{
									cursor: 'pointer',
									position: 'absolute',
									right: 0,
									color: styleConstant.COLOR.CTA.DEFAULT,
								}}>
								{lang === 'en'
									? 'Use Default Language'
									: 'Translate into English'}
							</div>
						</SelectContainer>

						{select === 'result' ? (
							<>
								<p className='mainText'>문진 결과를 확인해주세요</p>
								<p className='subText'>
									아는의사를 통해 내원하시는 환자의 문진을
									관리해보세요!
								</p>
								<BtnContainer>
									<PrimaryBtn
										className='start'
										label='아는의사 시작하기'
										onClick={() =>
											window.open(
												'https://www.knowingdoctor.com/'
											)
										}
									/>
								</BtnContainer>
								<CardContainer style={{ marginTop: '50px' }}>
									<PUserInfo
										name={profileInfo?.name}
										birth={`${
											date.getFullYear() -
											Number(
												profileInfo?.birthDate?.slice(
													0,
													4
												)
											) +
											1
										}세`}
										gender={profileInfo?.gender}
										job={profileInfo?.job}
									/>
									<ProfileQuestion
										questions={interview?.Profile.Questions}
									/>
									<SymptomInfo
										department={interview?.department}
										symptom={interview?.symptom}
										additionalSymptom={
											interview?.additionalSymptom
										}
										enDepartment={interview?.enDepartment}
										enSymptom={interview?.enSymptom}
									/>
									<PUserSymptom
										userSymptom={interview?.Questions}
									/>
								</CardContainer>
							</>
						) : (
							<>
								<p className='mainText'>
									{`${profileInfo?.name} 님의 다른 문진 결과도 확인해보세요`}
								</p>
								<p className='subText'>
									{
										'아는의사를 통해 내원하시는 환자의 문진을 관리해보세요!'
									}
								</p>
								<PreCardContainer>
									{preInterviewArr
										?.reverse()
										.map((preInterview, idx: number) => (
											<PreDiagnosisCardSimple
												key={idx}
												date={
													preInterview.createdAt
												}
												name={profileInfo?.name}
												newCard={false}
												departments={
													preInterview.department
												}
												symptom={
													preInterview.symptom
												}
												onClick={() =>
													clickCounseling(
														preInterview?.id
													)
												}
												currentId={interview?.id}
												id={preInterview?.id}
											/>
										))}
								</PreCardContainer>
							</>
						)}
					</Cover>
				</>
			) : (
				<LoadingModal isModalVisible={!init} />
			)}
		</>
	);
}
const Cover = styled.div`
	padding: ${styleConstant.BODY_PADDING};
	padding-bottom: 24px;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 24px 0 16px;
		word-break: keep-all;
	}
	.subText {
		font-size: ${styleConstant.FONT_SIZE.small};
		letter-spacing: -0.2px;
		/* margin: 0 0 48px; */
		word-break: keep-all;
	}
	button {
		margin-bottom: 16px;
	}
	@media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
		padding: 0;
		max-width: 1024px;
		margin: 0 auto;
		.start {
			/* display: none; */
			width: max-content;
		}
	}
	.lnChange {
		border: 1px solid black;
		padding: 0px 10px;
		border-radius: 15px;
		:hover {
			border-color: #27b4eb;
			color: #27b4eb;
		}
	}
`;
const Header = styled.div`
	display: flex;
	justify-content: center;
`;
const Logo = styled.img`
	width: 115px;
	height: 40px;
	margin: auto;
	margin: 15px 0;
`;

const CardContainer = styled.section`
	display: flex;
	flex-flow: column;
	gap: 16px;
	margin: 24px 0 32px;
	@media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
		width: 100%;
		max-width: 1024px;
		height: 1200px;
		padding-bottom: 50px;
		flex-wrap: wrap;
		flex-flow: column wrap;
		gap: 20px;
		justify-content: space-between;
	}
`;
const PreCardContainer = styled.div`
	margin: 16px 0 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
		width: 100%;
		margin: 48px 0 0;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 24px 20px;
	}
`;
const SelectContainer = styled.div`
	position: relative;
	display: flex;
	gap: 16px;
	margin: 0;
	div {
		font-size: ${styleConstant.FONT_SIZE.medium};
		letter-spacing: -0.2px;
		padding: 0;
		margin: 0;
		cursor: pointer;
	}
	.focus {
		font-weight: bold;
		border-bottom: solid 2px #1bbcff;
	}
`;

const BtnContainer = styled.div`
	margin: 0 0 24px;
	.start {
		@media only screen and (max-width: ${styleConstant.DESKTOP_SCREEN}) {
			display: none;
		}
	}
`;

const Content = styled.div`
	margin-top: 100px;
	text-align: center;
	white-space: pre;
`;
const MainText = styled.div`
	/* text-align: center; */
	margin-bottom: 16px;

	font-size: ${styleConstant.FONT_SIZE.large};
	font-weight: bold;
`;
const SubText = styled.div`
	/* text-align: center; */
	margin-bottom: 16px;
	color: #505759;
	font-size: ${styleConstant.FONT_SIZE.medium};
`;
