import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { TwoBtnModal } from 'components/modal';
import logo from 'assets/img/logo-horizontal.png';
import { ReactComponent as BackIcon } from 'assets/icon/backIcon.svg';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';

interface IHeaderBar {
	noBack?: boolean;
	loginPage?: boolean;
	nowPath?: string;
}

export function HeaderBar({ noBack = false, loginPage = false, nowPath }: IHeaderBar) {
	const navigate = useNavigate();
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	function onRouterBtn(path: string) {
		navigate(path);
	}

	function onBackBtn() {
		navigate(-1);
	}

	function onLogoutBtn() {
		setIsModalVisible(true);
	}

	function onModalCancel() {
		setIsModalVisible(false);
	}

	function onLogoutCheckBtn() {
		localStorage.clear();
		navigate(0);
	}

	useEffect(() => {
		if (document.documentElement.lang === 'en') {
			document.getElementById('korean-btn')?.click();
			document.getElementById('english-btn')?.click();
		}
	}, [document.documentElement.lang]);

	const now = new Date();
	const year = now.getFullYear();

	return (
		<>
			<Cover noBack={noBack}>
				<TwoBtnModal
					isModalVisible={isModalVisible}
					mainText='로그아웃 하시겠어요?'
					leftBtnClick={onModalCancel}
					leftBtnText='아니요'
					rightBtnClick={onLogoutCheckBtn}
					rightBtnText='로그아웃'
				/>
				<Header loginPage={loginPage}>
					<Link to='/'>
						<img src={logo} alt='header bar logo' />
					</Link>
					<MenuContainer loginPage={loginPage}>
						<p
							className={
								nowPath === menuConstant.COUNSELING ? 'select' : ''
							}
							onClick={() => onRouterBtn('/counseling')}>
							문진 상담
						</p>
						<p
							className={
								nowPath === menuConstant.PERSONAL_BRANDING
									? 'select'
									: ''
							}
							onClick={() => onRouterBtn('/personalbranding')}>
							퍼스널 브랜딩
						</p>
						<p
							className={
								nowPath === menuConstant.MY_INFO ? 'select' : ''
							}
							onClick={() => onRouterBtn('/myupdate')}>
							내 정보 수정
						</p>
						<p onClick={onLogoutBtn}>로그아웃</p>
					</MenuContainer>
				</Header>
				<BackIcon className='back' onClick={onBackBtn} />
			</Cover>
			{/* <FooterText>
				Powered by
				<span> ⓒ 아는의사 {year} </span>
			</FooterText> */}
		</>
	);
}

const Cover = styled.div<{ noBack: true | false }>`
	margin-bottom: 0;
	background-color: #fff;
	svg {
		cursor: pointer;
	}
	.back {
		display: ${({ noBack }) => (noBack ? 'none' : '')};
		margin: 32px 0 0;
	}
`;

const Header = styled.div<{ loginPage: true | false }>`
	width: 100%;
	height: 68px;
	display: flex;
	justify-content: ${({ loginPage }) => (loginPage ? 'center' : 'space-between')};
	align-items: center;
	img {
		width: 115px;
		height: 40px;
	}
`;

const MenuContainer = styled.div<{ loginPage: true | false }>`
	display: ${({ loginPage }) => (loginPage ? 'none' : 'flex')};
	font-size: ${styleConstant.FONT_SIZE.medium};
	font-weight: 500;
	padding-top: 34px;
	p {
		margin-left: 32px;
		cursor: pointer;
	}
	.select {
		font-weight: bold;
		color: #1bbcff;
	}
`;

const FooterText = styled.div`
	padding: 3px 12px;
	background-color: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(5px);
	border-radius: 10px;
	box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 1000;
	color: ${styleConstant.COLOR.GRAY_4};
	font-size: ${styleConstant.FONT_SIZE.moreSmall};
	font-weight: 500;
	span {
		color: ${styleConstant.COLOR.CTA.DEFAULT};
	}
`;
