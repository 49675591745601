import React, { useEffect } from "react";
import axios, { AxiosInstance } from "axios";
import { useNavigate } from "react-router-dom";
import routerConstant from "data/constant/routerConstant";
import tokenConstant from "data/constant/tokenConstant";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default function useAxios() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem(tokenConstant.accessToken);

  if (localStorage.getItem(tokenConstant.accessToken)) {
    axiosInstance.interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          "content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
    });
  }

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      if (err.response?.status === 401) {
        if (localStorage.getItem(tokenConstant.refreshToken) !== null) {
          try {
            const refreshAxios: AxiosInstance = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
            });
            const res = await refreshAxios.post("/auth/token/refresh", {
              refreshToken: localStorage.getItem(tokenConstant.refreshToken),
            });
            if (res.status === 200) {
              localStorage.setItem(
                tokenConstant.accessToken,
                res.data.accessToken,
              );
              localStorage.setItem(
                tokenConstant.refreshToken,
                res.data.refreshToken,
              );
              return await refreshAxios.request({
                ...err.config,
                headers: {
                  "content-Type": "application/json",
                  Authorization: `Bearer ${res.data.accessToken}`,
                },
              });
            } else {
              localStorage.clear();
              throw new Error();
            }
          } catch {
            localStorage.clear();
            navigate(routerConstant.HOME);
            navigate(0);
          }
        } else {
          localStorage.clear();
          navigate(routerConstant.HOME);
          navigate(0);
          throw new Error(err);
        }
        localStorage.clear();
        navigate(routerConstant.HOME);
        navigate(0);
        throw new Error(err);
      } else if (err.response?.status === 400) {
        return err;
      }
      localStorage.clear();
      navigate(routerConstant.HOME);
      navigate(0);
      throw new Error(err);
    },
  );

  return axiosInstance;
}
