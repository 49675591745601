/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { CategorySymptom } from './categorySymptom';
import styleConstant from 'data/constant/styleConstant';
import { getYear, format } from 'date-fns';
import { ReactComponent as RightArrowIcon } from 'assets/icon/rightArrowIcon.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icon/leftArrowIcon.svg';

export function UserSymptom({ userSymptom }: any) {
	const symptomCategory: any = [];

	userSymptom?.map((element: any) => {
		if (symptomCategory.length) {
			for (let i = 0; i < symptomCategory.length; i++) {
				if (symptomCategory[i][0].smallCategory === element.smallCategory) {
					symptomCategory[i].includes(element)
						? null
						: symptomCategory[i].push(element);
					break;
				} else {
					i === symptomCategory.length - 1
						? symptomCategory.push([element])
						: null;
				}
			}
		} else {
			symptomCategory.push([element]);
		}
	});

	return (
		<>
			{symptomCategory?.map((symptomList: any, idx: number) => (
				<Cover key={idx}>
					<Header key={idx}>
						<div>{symptomList[0].smallCategory}</div>
					</Header>
					<InfoContainer key={idx}>
						{symptomList.map((symptomQuestion: any, idx: number) =>
							symptomQuestion.answerType === '업로드' ? (
								<CategorySymptom
									key={idx}
									question={symptomQuestion.mainQuestion}
									answer={
										symptomQuestion.UserCounselingQuestion
											.answer
									}
									last={idx === symptomList.length - 1}
									isImg={true}
								/>
							) : symptomQuestion.smallCategory === '예약' ? (
								(() => {
									const startDate = new Date(
										symptomQuestion.UserCounselingQuestion.answer.split(
											';'
										)[1]
									);
									const endDate = new Date(
										symptomQuestion.UserCounselingQuestion.answer.split(
											';'
										)[2]
									);

									return (
										<Dp
											startDate={startDate}
											endDate={endDate}
										/>
									);
								})()
							) : (
								<CategorySymptom
									key={idx}
									question={symptomQuestion.mainQuestion}
									answer={
										symptomQuestion.UserCounselingQuestion
											.answer
									}
									last={idx === symptomList.length - 1}
									answerType={symptomQuestion.answerType}
									inputType={symptomQuestion.inputType}
								/>
							)
						)}
					</InfoContainer>
				</Cover>
			))}
		</>
	);
}

const Cover = styled.div`
	margin-bottom: 48px;
	width: 328px;
`;
const Header = styled.div`
	margin: 0 0 8px 0;
	display: flex;
	justify-content: space-between;
	font-size: ${styleConstant.FONT_SIZE.small};
	font-weight: bold;
`;

const InfoContainer = styled.div`
	padding: 16px;
	margin: 0;
	background-color: #f8f8f8;
	border-radius: 20px;
	width: 328px;
`;

const Dp = ({ startDate, endDate }: any) => {
	const startTime = format(startDate, 'HH:mm');
	console.log('endDate', typeof endDate, endDate);

	let endTime = '';

	if (!isNaN(Date.parse(endDate))) {
		endTime = format(endDate, 'HH:mm');
	}

	const dayname = {
		Sunday: '일',
		Monday: '월',
		Tuesday: '화',
		Wednesday: '수',
		Thursday: '목',
		Friday: '금',
		Saturday: '토',
	};

	return (
		<>
			<PickerBox startTime={startTime} endTime={endTime}>
				<CustomDatePicker
					//@ts-ignore
					formatWeekDay={(nameOfDay) => dayname[nameOfDay]}
					selected={startDate}
					shouldCloseOnSelect={false}
					open={true}
					dateFormat='yyyy-MM-dd'
					placeholderText={'일자'}
					onChange={() => {}}
					startDate={startDate}
					endDate={endDate}
					renderCustomHeader={({
						date,
						decreaseMonth,
						increaseMonth,
						prevMonthButtonDisabled,
						nextMonthButtonDisabled,
					}) => (
						<div className='header'>
							<LeftArrowIcon type='button' onClick={decreaseMonth} />
							{getYear(date) + '년 ' + format(date, 'M') + '월'}
							<RightArrowIcon type='button' onClick={increaseMonth} />
						</div>
					)}></CustomDatePicker>
			</PickerBox>
		</>
	);
};

const PickerBox = styled.div<{ startTime?: string; endTime?: string }>`
	.dateInputBox {
		border: none;
		width: auto;
		height: 100%;
		outline: none;
		padding: 0;
		margin: 0;
		overflow: hidden;
		white-space: nowrap;
		background-color: ${styleConstant.COLOR.WHITE_BG};
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px 10px;
		border-bottom: 1px solid #eaeaea;
	}
	.react-datepicker-wrapper {
		width: 100%;
		position: relative;
		height: 100%;
	}

	.react-datepicker__input-container {
		display: none;
	}

	.react-datepicker__aria-live {
		display: none;
	}

	.react-datepicker-popper {
		width: 296px;
		height: auto;
		position: relative !important;
		transform: none !important;
		background-color: ${styleConstant.COLOR.WHITE_BG};
		align-items: center;
		border-radius: 12px;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
		padding: 12px 0 24px;
	}
	.react-datepicker {
		width: 100%;
		margin: 0;
	}
	.react-datepicker__month {
		pointer-events: none;
	}
	.react-datepicker__current-month {
		font-size: 16px;
		font-weight: 500;
		color: ${styleConstant.COLOR.GRAY_5};
		display: flex;
		justify-content: center;
	}
	.react-datepicker__day-names {
		display: flex;
		justify-content: space-between;
		padding: 10px 20px 0;
	}
	.react-datepicker__week {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 25px 0;
		padding: 0 20px;
	}
	.react-datepicker__day-name {
		width: 36px;
		height: 36px;
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
		font-size: 12px;
		font-weight: 400;
		color: ${styleConstant.COLOR.GRAY_3};
	}
	.react-datepicker__day {
		width: 36px;
		height: 36px;
		display: flex;
		justify-content: center;
		font-size: 14px;
		font-weight: 400;
		text-align: center;
		align-items: center;
		color: ${styleConstant.COLOR.GRAY_6};
		border-radius: 8px;
		:hover {
			background-color: ${styleConstant.COLOR.GRAY_1};
		}
		transition: background-color 0.2s ease-in-out;
	}
	.react-datepicker__day--today {
		&::before {
			content: '';
			position: absolute;
			width: 30px;
			height: 33px;
			border-bottom: 2px solid ${styleConstant.COLOR.GRAY_3};
			z-index: -1;
		}
	}
	.react-datepicker__day--selected {
		background-color: ${styleConstant.COLOR.CTA.DEFAULT};
		color: ${styleConstant.COLOR.WHITE_BG};
		border-radius: 8px;
		&:focus {
			outline: none !important;
		}
	}
	.react-datepicker__day--in-range {
		background-color: #d1f2ff;
		border-radius: 0px;
		&::before {
			content: '';
			position: absolute;
			width: 60px;
			height: 36px;
			background-color: #d1f2ff;
			z-index: -1;
			border-bottom: none ${styleConstant.COLOR.GRAY_3};
		}
	}
	.react-datepicker__day--keyboard-selected {
		&:focus {
			outline: none !important;
		}
	}

	.react-datepicker__day--range-start {
		background-color: ${styleConstant.COLOR.CTA.DEFAULT};
		color: ${styleConstant.COLOR.WHITE_BG};
		border-radius: 8px;
		&::before {
			content: '';
			position: absolute;
			width: 0px;
		}
		&::after {
			content: ${({ startTime }) => `"${startTime}"`};
			position: absolute;
			transform: translate(-5%, 125%);
			color: ${styleConstant.COLOR.CTA.DEFAULT} !important;
		}
	}
	.react-datepicker__day--range-end {
		background-color: ${styleConstant.COLOR.CTA.DEFAULT} !important;
		color: ${styleConstant.COLOR.WHITE_BG};
		border-radius: 8px;
		&::before {
			content: '';
			position: absolute;
			width: 0px;
		}
		&::after {
			content: ${({ endTime }) => `"${endTime}"`};
			position: absolute;
			transform: translate(-5%, 125%);
			color: ${styleConstant.COLOR.CTA.DEFAULT} !important;
		}
	}

	.react-datepicker__day--outside-month {
		color: ${styleConstant.COLOR.GRAY_3};
	}
	.react-datepicker__day--disabled {
		color: ${styleConstant.COLOR.GRAY_3};
	}
`;

const CustomDatePicker = styled(DatePicker)`
	border: none;
	width: 100px;
	height: 100%;
	outline: none;
`;
