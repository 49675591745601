import { createSlice } from "@reduxjs/toolkit";
import loginTypeConstant from "data/constant/loginTypeConstant";
import tokenConstant from "data/constant/tokenConstant";

interface ILoginInitState {
  phoneNumber: string;
  countryCode: string;
  doctorId: string;
  accessToken: string;
  refreshToken: string;
}

const loginInitState: ILoginInitState = {
  phoneNumber: "",
  countryCode: "+82",
  doctorId: "",
  accessToken: "",
  refreshToken: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState: loginInitState,
  reducers: {
    addToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.doctorId = action.payload.doctorId;
      localStorage.setItem(
        tokenConstant.accessToken,
        action.payload.accessToken,
      );
      localStorage.setItem(
        tokenConstant.refreshToken,
        action.payload.refreshToken,
      );
      localStorage.setItem(tokenConstant.doctorId, action.payload.doctorId);
    },
    logout: (state) => {
      state.accessToken = "";
      localStorage.removeItem(tokenConstant.accessToken);
      state.refreshToken = "";
      localStorage.removeItem(tokenConstant.refreshToken);
      state.doctorId = "";
      localStorage.removeItem(tokenConstant.doctorId);
    },
    //
    login: (state, action) => {
      state.phoneNumber = action.payload.phoneNumber;
      state.countryCode = action.payload.countryCode;
    },
    allReset: (state) => {
      state.phoneNumber = "";
      state.countryCode = "+82";
      state.doctorId = "";
      state.accessToken = "";
      state.refreshToken = "";
    },
  },
});

export const { addToken, logout, login, allReset } = loginSlice.actions;
export default loginSlice.reducer;
