import React from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/en-au";
import styleConstant from "data/constant/styleConstant";
import { PrimaryBtn } from "components/button";

export interface PreDiagnosisCardProps {
  date: string;
  name?: string;
  newCard: boolean;
  departments: string;
  symptom: string;
  currentId?: number;
  id: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function PreDiagnosisCardSimple({
  date,
  name,
  newCard,
  departments,
  symptom,
  currentId,
  id,
  onClick,
}: PreDiagnosisCardProps) {
  const department = departments?.split(";").slice(0, -1);

  return (
    <Cover>
      <Container newCard={newCard}>
        <p className="date">{`${moment(date).format("MM월 DD일")} 문진`}</p>
        <p>
          <span>{name}</span>
          <span className="new">NEW</span>
        </p>
      </Container>
      <InfoContainer>
        <div>
          <p className="title">{"진료과"}</p>
          <p>
            {department?.map((element, idx) =>
              idx === department.length - 1 ? element : `${element}, `
            )}
          </p>
        </div>
        <div>
          <p className="title">{"증상"}</p>
          <p>{symptom}</p>
        </div>
      </InfoContainer>
      <BottomBtn
        onClick={onClick}
        able={id === currentId ? "disable" : "none"}
        disabled={id === currentId}
      >
        {id === currentId ? "현재 보고 있는 문진" : "문진 보기"}
      </BottomBtn>
    </Cover>
  );
}

const Cover = styled.div`
  min-width: 250px;
  width: 100%;
  /* min-height: 292px; */
  margin: 0;
  padding: 24px;
  background-color: #fff;
  border: solid 1px #cfcfcf;
  border-radius: 20px;
  font-family: "Noto Sans KR", sans-serif;
  text-align: left;
  position: relative;
  /* cursor: pointer; */
  /* word-break: keep-all; */
  @media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
    width: 328px;
  }
  :hover {
    border: 1px solid #27b4eb;
  }
  :active {
    border: 1px solid #0083b1;
  }
`;

const Container = styled.div<{ newCard: true | false }>`
  text-align: left;
  margin: 0;
  p {
    margin-top: 8px;
    margin-bottom: 0;
  }
  .date {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 140%;
  }
  span {
    font-size: ${styleConstant.FONT_SIZE.medium};
    font-weight: bold;
    margin-right: 4px;
  }
  .new {
    display: ${({ newCard }) => (newCard ? "inline" : "none")};
    width: 29px;
    height: 18px;
    padding: 1px 8px;
    background-color: rgba(27, 188, 255, 0.5);
    border-radius: 10px;
    color: #fff;
    font-size: 0.625rem;
    // font-size: 10px;
    line-height: 140%;
    // line-height: 12px;
    font-weight: bold;
    text-align: center;
  }
  .waiting {
    display: none;
    @media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
      display: inline;
      width: 63px;
      padding: 0 8px 2px;
      color: #fff;
      background-color: #1bbcff;
      font-size: ${styleConstant.FONT_SIZE.moreSmall};
      font-weight: 700;
      border-radius: 10px;
      margin: 0 0 0 8px;
      vertical-align: 1px;
    }
  }
  .progress {
    display: none;
    @media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
      display: inline;
      padding: 0 8px 2px;
      color: #fff;
      background-color: #2026ce;
      font-size: ${styleConstant.FONT_SIZE.moreSmall};
      font-weight: 700;
      border-radius: 10px;
      margin: 0 0 0 8px;
      vertical-align: 1px;
    }
  }
  .complete {
    display: none;
    @media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
      display: inline;
      padding: 0 8px 2px;
      color: #fff;
      background-color: #969696;
      font-size: ${styleConstant.FONT_SIZE.moreSmall};
      font-weight: 700;
      border-radius: 10px;
      margin: 0 0 0 8px;
      vertical-align: 1px;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  margin: 24px 0 0;
  div {
    width: 50%;
    margin: 0;
    p {
      margin: 0;
      font-size: ${styleConstant.FONT_SIZE.medium};
      font-weight: bold;
      color: #222;
    }
    .title {
      margin-bottom: 8px;
      font-size: ${styleConstant.FONT_SIZE.small};
      font-weight: normal;
      color: #969696;
    }
  }
`;

const BottomBtn = styled.button<{ able: "none" | "disable" }>`
  width: 100%;
  height: 48px;
  margin: 0;
  margin-top: 16px;
  padding: 12px 16px;
  text-align: center;
  background-color: ${({ able }) => (able === "disable" ? "#ECF0F0" : "#fff")};
  border: ${({ able }) =>
    able === "disable" ? "solid 1px #BEC6C4" : "solid 1px #cfcfcf"};
  color: ${({ able }) =>
    able === "disable" ? "#919D9D" : able === "none" ? "#222" : "#e44b55"};
  border-radius: 15px;

  font-size: ${styleConstant.FONT_SIZE.medium};
  font-weight: ${({ able }) => (able === "disable" ? "bold" : "500")};
  letter-spacing: -0.2px;
  cursor: pointer;
`;
