import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

interface PrimaryInputProps {
	autofocus?: boolean;
	type?: string;
	label?: string;
	icon?: ReactElement<any, any>;
	value: any;
	placeHolder?: string;
	close?: boolean;
	check?: boolean;
	checkText?: string;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function PrimaryInput({
	autofocus = false,
	type = 'text',
	label = '',
	icon,
	value,
	placeHolder,
	close = false,
	check = true,
	checkText,
	onKeyDown,
	onChange,
}: PrimaryInputProps) {
	const [handleFocus, setHandleFocus] = useState<boolean>(false);

	function onInputFocus() {
		setHandleFocus(!handleFocus);
	}

	const customRef = useRef<any>(null);

	useEffect(() => {
		if (autofocus && customRef.current) {
			customRef.current.focus();
		}
	}, []);

	return (
		<Cover>
			{label !== '' ? <p>{label}</p> : null}
			<InputContainer focus={handleFocus} close={close} check={check}>
				{icon}
				<Primary
					ref={customRef}
					type={type}
					value={value}
					onChange={onChange}
					onFocus={onInputFocus}
					onBlur={onInputFocus}
					onKeyDown={onKeyDown}
					placeholder={placeHolder}
					close={close}
				/>
			</InputContainer>
			{checkText ? (
				<CheckText check={check}>
					<span>!</span>
					{checkText}
				</CheckText>
			) : null}
		</Cover>
	);
}

const Cover = styled.div`
	width: 100%;
	margin: 0;
	font-family: 'Noto Sans KR';
	p {
		margin: 0 0 8px;
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		line-height: ${styleConstant.LINE_HEIGHT.moreSmall};
		color: ${styleConstant.COLOR.GRAY_7};
		font-weight: 500;
	}
`;

const InputContainer = styled.div<{
	focus: boolean;
	close: boolean;
	check: boolean;
}>`
	width: 100%;
	height: 48px;
	padding: ${({ focus, check }) => (focus ? '9px 17px' : check ? '9px 17px' : '8px 16px')};
	border: ${({ focus, check }) =>
		focus
			? `solid 2px ${styleConstant.COLOR.CTA.DEFAULT}`
			: check
			? `solid 1px ${styleConstant.COLOR.GRAY_3}`
			: `solid 2px ${styleConstant.COLOR.STATUS.CAUTION}`};
	border-radius: 30px;
	margin: 10px 0;
	background-color: ${({ close }) => (close ? '#e6e6e6' : styleConstant.COLOR.WHITE_BG)};
	display: flex;
	align-items: center;
	gap: 10px;
	line-height: 30px;
	svg {
		margin-top: 2px;
	}
	:hover {
		border: 1px solid ${styleConstant.COLOR.CTA.DEFAULT};
	}
	:focus-within {
		border: solid 1px ${styleConstant.COLOR.CTA.DEFAULT};
		box-shadow: 0 0 0 1px ${styleConstant.COLOR.CTA.DEFAULT};
	}
`;

const Primary = styled.input<{ close: true | false }>`
	display: ${({ close }) => (close ? 'none' : '')};
	width: 95%;
	margin: 0;
	border: none;
	font-size: ${styleConstant.FONT_SIZE.medium};
	line-height: ${styleConstant.LINE_HEIGHT.medium};
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&:focus {
		outline: none;
	}
	padding-bottom: 5px;
`;

const CheckText = styled.p<{ check: true | false }>`
	display: ${({ check }) => (check ? 'none' : 'flex')};
	font-weight: 500 !important;
	font-size: ${styleConstant.FONT_SIZE.moreSmall} !important;
	color: ${styleConstant.COLOR.GRAY_8} !important;
	margin-top: 8px !important;
	margin-bottom: 0 !important;
	span {
		width: 16px;
		height: 16px;
		color: ${styleConstant.COLOR.WHITE_BG};
		text-align: center;
		padding-left: 1px;
		background-color: ${styleConstant.COLOR.STATUS.CAUTION};
		border-radius: 8px;
		line-height: 17px;
		margin-right: 8px;
	}
`;
