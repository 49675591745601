import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';
import { ReactComponent as CancelBtn } from 'assets/icon/inputCancelIcon.svg';

interface SearchInputProps {
	type?: string;
	label?: string;
	value: any;
	placeHolder?: string;
	inputmode?: 'text' | 'numeric';
	pattern?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function SearchInput({
	type = 'text',
	label = '',
	value,
	placeHolder,
	inputmode = 'text',
	pattern = '',
	onChange,
	onCancel,
}: SearchInputProps) {
	const [handleFocus, setHandleFocus] = useState<boolean>(false);

	function onInputFocus() {
		setHandleFocus(!handleFocus);
	}

	return (
		<Cover>
			{label !== '' ? <p>{label}</p> : null}
			<InputContainer focus={handleFocus}>
				<Primary
					type={type}
					value={value}
					onChange={onChange}
					onFocus={onInputFocus}
					onBlur={onInputFocus}
					placeholder={placeHolder}
					inputMode={inputmode}
					pattern={pattern}
				/>
				<span onClick={onCancel}>
					<CancelBtn />
				</span>
			</InputContainer>
		</Cover>
	);
}

const Cover = styled.div`
	width: 100%;
	p {
		text-align: start;
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		color: #969696;
		margin: 0 0 4px 0;
	}
`;

const InputContainer = styled.div<{ focus: true | false }>`
	box-sizing: border-box;
	width: 100%;
	height: 48px;
	padding: 8px 16px;
	border: ${({ focus }) => (focus ? 'solid 1px #1bbcff' : 'solid 1px #cfcfcf')};
	border-radius: 25px;
	margin: 0;
	background-color: #fff;
	display: flex;
	line-height: 30px;

	span {
		width: 15px;
		height: 20px;
		border-radius: 8px;
		color: #fff;
		text-align: center;
		margin: auto 0;
		cursor: pointer;
	}
`;

const Primary = styled.input`
	width: 100%;
	margin-left: 10px;
	border: none;
	font-size: ${styleConstant.FONT_SIZE.medium};
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&:focus {
		outline: none;
	}
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) {
			padding: 0;
		}
		@supports (-webkit-overflow-scrolling: touch) {
			padding: 0;
		}
	}
`;
