import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer/rootReducer';

export default function HospitalSelectBox({ hospital, selectedHospitalId, handleHospital }: any) {
	const [isSelected, setIsSelected] = useState<boolean>(false);
	let hospitalId = useSelector((state: RootState) => state.hospital.selectedHospitalId);

	useEffect(() => {
		if (hospital?.id === Number(hospitalId)) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	}, [hospitalId]);

	return (
		<HospitalBox
			onClick={handleHospital}
			id={hospital?.id}
			className={hospital?.hospitalName}
			hospitalId={hospital?.id}
			hospitalName={hospital?.hospitalName}
			isSelected={isSelected}>
			<div className={hospital?.hospitalName} id={hospital?.id} />
			{hospital?.hospitalName}
		</HospitalBox>
	);
}

const HospitalBox = styled.div<{
	hospitalId: string;
	hospitalName: string;
	isSelected: true | false;
}>`
	height: 50px;
	width: auto;
	padding: 7px 20px 7px 15px;
	border: ${({ isSelected }) => (isSelected ? '2px solid #27b4eb' : '1.5px solid #bec6c4')};
	border-radius: 25px;
	gap: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	div {
		height: 23px;
		width: 23px;
		padding: 0;
		margin: 0;
		border-radius: 23px;
		border: ${({ isSelected }) =>
			isSelected ? '7px solid #27b4eb;' : '2px solid #bec6c4'};
	}
`;
