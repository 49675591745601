import styleConstant from 'data/constant/styleConstant';
import React from 'react';
import styled from 'styled-components';

interface RequirementItemProps {
	agree: boolean;
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}
export function IsNotCheck({ agree, onClick }: RequirementItemProps) {
	return <IsNotCheckBox agree={agree} onClick={onClick} />;
}

const IsNotCheckBox = styled.div<{ agree: true | false }>`
	min-width: 20px;
	width: 20px;
	height: 20px;
	border: ${({ agree }) =>
		agree
			? `solid 1px ${styleConstant.COLOR.GRAY_2}`
			: `solid 2px ${styleConstant.COLOR.STATUS.CAUTION}`};
	border-radius: 6px;
	cursor: pointer;
	background-color: white;
	@media only screen and (min-width: 600px) {
		:hover {
			border: 1px solid ${styleConstant.COLOR.CTA.DEFAULT};
		}
	}
	:active {
		border: 2px solid ${styleConstant.COLOR.CTA.DEFAULT};
	}
`;
