import styleConstant from "data/constant/styleConstant";
import React from "react";
import styled from "styled-components";

interface SymptomInfoProps {
  department?: string;
  enDepartment?: string;
  symptom?: string;
  enSymptom?: string;
  additionalSymptom?: string;
}

export function SymptomInfo({
  department,
  symptom,
  additionalSymptom,
  enDepartment,
  enSymptom,
}: SymptomInfoProps) {
  const splitDepartment = department?.split(";");
  const splitEnDepartment = enDepartment?.split(";");
  const splitEnSymptom = enSymptom?.split(";");

  return (
    <Cover>
      <Header>
        <div>{"증상"}</div>
      </Header>
      <InfoContainer>
        <p>{"어떤 도움이 필요하세요?"}</p>
        {/* <p>"어디가 불편하신가요?"</p> */}
        <p className="info">
          {splitDepartment?.map((element) => `${element} `)}
        </p>
        <p>{"어떤 내용인가요?"}</p>
        <p className="info">{symptom}</p>
        <p>{"어떤 증상인지 상세히 알려주세요"}</p>
        <p className="info last">{additionalSymptom}</p>
      </InfoContainer>
    </Cover>
  );
}

const Cover = styled.div`
  margin: 0;
  width: 100%;
  @media only screen and (min-width: ${styleConstant.DESKTOP_SCREEN}) {
    width: 328px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${styleConstant.FONT_SIZE.small};
  font-weight: bold;
`;

const InfoContainer = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  padding: 16px;
  margin: 8px 0 0;
  border-radius: 16px;
  font-size: ${styleConstant.FONT_SIZE.small};
  p {
    margin: 0 0 8px;
  }
  .info {
    font-size: ${styleConstant.FONT_SIZE.medium};
    font-weight: bold;
    margin: 0 0 24px;
  }
  .last {
    margin: 0;
  }
`;
