import React, { forwardRef } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

export interface PreDiagnosisCardProps {
	name: any;
	gender: string;
	birth: string;
	date: string;
	state: string;
	department: string;
	symptom: string;
	mainText: string;
	isSelect?: boolean;
	isSearch?: boolean;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function PreDiagnosisCard({
	name,
	gender,
	birth,
	date,
	state,
	department,
	symptom,
	mainText,
	isSearch = false,
	isSelect = false,
	onClick,
}: PreDiagnosisCardProps) {
	const splitDepartment = department?.split(';').slice(0, -1);
	function Counseling({ state }: any) {
		if (state === 'waiting') {
			return <span className='standby'>상담 대기</span>;
		} else if (state === 'progress') {
			return <span className='ing'>상담 중</span>;
		} else if (state === 'end') {
			return <span className='end'>상담 완료</span>;
		} else {
			return null;
		}
	}
	const genderSwitch = (genderNum: number) => {
		switch (genderNum) {
			case 1:
				return '남자';
			case 2:
				return '여자';
			default:
				return '둘 다 아니에요';
		}
	};
	return (
		<Cover isSelect={isSelect} onClick={onClick}>
			<Container>
				<NameContainer>
					{isSearch ? (
						<span className='name'>{name}</span>
					) : (
						<span className='name'>{name && name}</span>
						// <span className="name">{name && name[0] + "**"}</span>
					)}
					<span className='dot' />
					<span>{genderSwitch(Number(gender))}</span>
					<span className='dot' />
					<span>{birth}</span>
				</NameContainer>
				<p>
					<span className='date'>{date}</span>
					<Counseling state={state} />
				</p>
			</Container>
			<InfoContainer>
				<div>
					<p className='title'>진료과</p>
					<p className='department'>
						{splitDepartment?.map((element, idx) =>
							idx === splitDepartment.length - 1
								? element
								: `${element}, `
						)}
					</p>
				</div>
				<div className='second'>
					<p className='title'>증상</p>
					<p>{symptom}</p>
				</div>
			</InfoContainer>
			{/* <Text>
				<p>상세 설명</p>
				<div>{mainText}</div>
			</Text> */}
		</Cover>
	);
}

const Cover = styled.div<{ isSelect: true | false }>`
	box-sizing: border-box;
	width: 328px;
	height: auto;
	margin: 0;
	padding: 12px 24px;
	background-color: #fff;

	border: ${({ isSelect }) =>
		isSelect
			? `solid 2px ${styleConstant.COLOR.CTA.DEFAULT}`
			: `solid 1px ${styleConstant.COLOR.GRAY_2}`};
	border-radius: 25px;

	box-shadow: ${({ isSelect }) =>
		isSelect ? `0px 8px 8px rgba(0, 0, 0, 0.15)` : `0px 5px 5px rgba(0, 0, 0, 0.05)`};
	transform: ${({ isSelect }) => (isSelect ? `translatey(-3px)` : ``)};

	transition: all 0.3s;

	font-family: 'Noto Sans KR', sans-serif;
	text-align: left;
	position: relative;
	cursor: pointer;

	:hover {
		//border: 1px solid ${styleConstant.COLOR.CTA.DEFAULT};
		box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
		transform: translatey(-3px);
	}
	:active {
		border: 1px solid ${styleConstant.COLOR.CTA.PRESSED};
		box-shadow: none;
	}
`;

const Container = styled.div`
	text-align: left;
	p {
		margin-top: 8px;
		margin-bottom: 0;
	}
	.date {
		font-size: ${styleConstant.FONT_SIZE.small};
		color: #969696;
		font-weight: normal;
		margin-right: 16px;
	}
	span {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin-right: 4px;
	}
	.standby {
		/* width: 29px; */
		height: 18px;
		padding: 1px 8px;
		margin: 0;
		background-color: #1bbcff;
		border-radius: 8px;
		color: #fff;
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		font-weight: bold;
		text-align: center;
	}
	.ing {
		height: 18px;
		padding: 1px 8px;
		margin: 0;
		background-color: #2026ce;
		border-radius: 8px;
		color: #fff;
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		font-weight: bold;
		text-align: center;
	}
	.end {
		height: 18px;
		padding: 1px 8px;
		margin: 0;
		background-color: #969696;
		border-radius: 8px;
		color: #fff;
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		font-weight: bold;
		text-align: center;
	}
`;
const NameContainer = styled.p`
	display: flex;

	span {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: normal;
		line-height: 29px;
	}
	.dot {
		width: 6px;
		height: 6px;
		border-radius: 3px;
		background-color: #e6e6e6;
		margin: auto 8px;
		line-height: 0;
	}
	.name {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
	}
`;

const InfoContainer = styled.div`
	display: flex;
	margin-top: 16px;
	flex-wrap: wrap;
	div {
		margin-bottom: 0;
		width: 100px;
		p {
			font-size: ${styleConstant.FONT_SIZE.medium};
			font-weight: bold;
			color: #222;
		}
		.title {
			margin-bottom: 8px;
			font-size: ${styleConstant.FONT_SIZE.small};
			font-weight: normal;
			color: #969696;
		}
		.department {
			word-break: keep-all;
		}
	}
	.second {
		margin: 0 0 0 9px;
	}
`;

const Text = styled.div`
	p {
		margin: 16px 0 8px 0;
		color: #969696;
	}
	font-size: ${styleConstant.FONT_SIZE.small};
	letter-spacing: -0.2px;
	color: #222;
	line-height: 1.43;
	height: 108px;
	overflow-y: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
`;
