/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	HOME: '/',
	LOGIN: '/login',
	SIGNUP: '/login/signup',
	CERTIFICATE: '/login/certificate',
	ASSOCITATE: '/signup/associate',
	SIGNUP_WRITEINFO: '/signup/writeinfo',
	COUNSELING: '/counseling',
	PERSONALBRANDING: '/personalbranding',
	MYUPDATE: '/myupdate',
};
