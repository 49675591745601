import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';
import { ReactComponent as UpArrow } from 'assets/icon/upArrow.svg';
import { ReactComponent as EditIcon } from 'assets/icon/editIcon.svg';
import { ReactComponent as AddIcon } from 'assets/icon/addIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icon/deleteIcon.svg';
import { PlusCircleFilled } from '@ant-design/icons';
import useAxios from 'api/axiosCustom';
import { async } from '@firebase/util';

interface ChatInputProps {
	value: string;
	setValue: any;
	isEdit: any;
	placeholder?: string;
	type?: 'button' | 'submit';
	isEnd?: boolean;
	isStart?: boolean;
	inputRef?: React.MutableRefObject<any>;
	fileInputRef: React.MutableRefObject<any>;
	onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	file: any;
	setFile: any;
}

export function ChatInput({
	value,
	setValue,
	isEdit,
	placeholder,
	type = 'button',
	isEnd = false,
	isStart = false,
	inputRef,
	onKeyDown,
	onChange,
	onClick,
	file,
	setFile,
	fileInputRef,
}: ChatInputProps) {
	const axios = useAxios();

	const autoSizing = (e: any) => {
		let t: any;
		e.onfocus = function () {
			t = setInterval(function () {
				e.style.height = '1px';
				e.style.height = e.scrollHeight + 3 + 'px';
			}, 100);
		};
		e.onblur = function () {
			clearInterval(t);
		};
	};

	useEffect(() => {
		autoSizing(document.getElementById('autoSizingBox'));
	}, []);

	const handleUploadFile = () => {
		document.getElementById('upload')?.click();
	};

	const onImgChange = async (e: any) => {
		console.log('upload');

		const file = e.target.files[0];
		if (file) {
			const formData = new FormData();
			formData.append('image', file);
			console.log('formData', formData);
			const res = await axios.post(`/image?type=chat`, formData);

			console.log('res.status', res.status, res.data, file);
			const length = file?.name.length;
			const firstFive = file?.name.slice(0, 10);

			const extension = file?.name.split('.')[file?.name.split('.').length - 1];
			const newFileName = length > 14 ? firstFive + '~ .' + extension : file?.name;

			const newFile = {
				src: res.data.src,
				name: newFileName,
				type: file.type,
			};

			setFile(newFile);
			setValue(JSON.stringify(newFile));
		}
	};

	const imgRegex = /image/;
	return (
		<>
			<Cover isEnd={isEnd || isStart} isBlank={value === ''} isEdit={isEdit !== null}>
				<PreviewBox>
					<div className='priviewItem'>
						{imgRegex.test(file?.type) ? (
							<img src={file?.src} alt='uploaded' />
						) : (
							<></>
						)}
					</div>
				</PreviewBox>
				{value && (
					<p
						style={{
							position: 'absolute',
							top: '-28px',
							right: '5px',
							color: styleConstant.COLOR.CTA.DEFAULT,
							fontWeight: 500,
							zIndex: 100,
						}}>
						shift + enter 로 전송
					</p>
				)}
				{isEnd ? <p className='end'>종료된 대화입니다.</p> : null}
				{isStart ? <p className='end'>상담을 시작해주세요.</p> : null}
				<Input
					id='autoSizingBox'
					onKeyDown={onKeyDown}
					ref={inputRef}
					value={value.includes('{"src"') ? ' ' : value}
					placeholder={placeholder}
					onChange={onChange}
				/>

				{/* {file && <img src={file} alt='' />} */}

				<button type={type} onClick={onClick} disabled={isEnd}>
					{isEdit === null ? (
						value.length === 0 && !file?.src ? (
							<AddIcon onClick={handleUploadFile} />
						) : (
							<UpArrow />
						)
					) : (
						<EditIcon />
					)}
				</button>
				<input
					ref={fileInputRef}
					id='upload'
					className='upload'
					type='file'
					//accept='image/*;.pdf;.docx;.hwp'
					accept='*'
					onChange={onImgChange}
				/>
				{file?.src && (
					<div className='priviewTitle'>
						<span>
							{file?.name}
							<span className='icon'>
								<DeleteIcon
									onClick={() => {
										fileInputRef.current.value = '';
										setFile({});
										setValue('');
									}}
								/>
							</span>
						</span>
					</div>
				)}
			</Cover>
		</>
	);
}

const Cover = styled.div<{ isEnd: true | false; isBlank: true | false; isEdit: true | false }>`
	box-sizing: border-box;
	position: absolute;
	width: -webkit-calc(100% - 50px);
	bottom: 20px;
	box-shadow: ${({ isEdit }) => (isEdit ? `0 0px 8px 0 rgb(0 0 0 / 15%)` : '')}};
  border: ${({ isEdit }) => (isEdit ? `solid 1.5px #27b4eb` : `solid 1px #cfcfcf`)}};
	border-radius: 25px;
	background-color: ${({ isEnd }) => (isEnd ? '#e6e6e6' : '#fff')};
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 102;
	button {
		width: 35px;
		height: 35px;
		transition: all 0.3s;
		//opacity: ${({ isBlank }) => (isBlank ? '0%' : '100%')};
		position: absolute;
		padding: 6px 0 0 0;
		bottom: 6px;
		right: 5px;
		border-radius: 25px;
		border: none;
		background-color: #1bbcff;
		color: #fff;
		font-size: ${styleConstant.FONT_SIZE.small};
		font-weight: bold;
		z-index: 12;
		&:hover{
			transform:scale(1.1);
			filter: brightness(1.1);
		}
	}
	textarea {
		display: ${({ isEnd }) => (isEnd ? 'none' : '')};
		border-radius: 25px;
		z-index: 12;
		width: 100%;
	}
	.end {
		margin: 0;
		padding: 0 16px;
		line-height: 48px;
		color: #969696;
		z-index: 12;
	}
	.upload{
		display:none;
		z-index: 12;
	}

	.priviewTitle {
		position: absolute;
		display: flex;
		justify-content: left;
		align-items: left;
		/* background-color: red; */
		margin: 0px;
		z-index: 20;

		span{
			font-size: 16px;
			margin: 10px 20px;
			padding: 4px 35px 4px 13px;
			border-radius: 15px;
			background-color: ${styleConstant.COLOR.GRAY_2};
			font-weight: 500;
			dispaly: flex;
			flex-direction: row;
			position: relative;
		}
		.icon{
			margin: 0px;
			padding: 0px;
			top: 6px;
			right:6px;
			position: absolute;
		}

		DeleteIcon{
			opacity: 0.5;
		}
`;

const Input = styled.textarea`
	box-sizing: border-box;
	border: none;
	border-radius: 20px;
	width: 97%;
	height: 48px;
	max-height: 150px;
	padding: 10px 20px;
	font-size: ${styleConstant.FONT_SIZE.medium};
	word-wrap: break-word;
	word-break: break-all;
	overflow: auto;
	resize: none;
	z-index: 11;
	&:focus {
		outline: none;
	}
`;

const PreviewBox = styled.div`
	height: auto;
	background-color: ${styleConstant.COLOR.GRAY_1};
	width: 100%;
	z-index: 10;
	position: absolute;
	bottom: 0px;
	border-radius: 30px;

	.priviewItem {
		display: flex;
		justify-content: left;
		align-items: right;
		/* background-color: orange; */
	}
	img {
		height: auto;
		width: 150px;
		padding: 10px;
		margin: 20px 20px 60px;
		background-color: ${styleConstant.COLOR.GRAY_2};
		border-radius: 10px;
	}
`;
