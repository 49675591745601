import React, { useEffect } from 'react';
import './App.css';
import 'antd/dist/antd.css';
import { Router } from './routes';
import axios from 'api/axiosCustom';
import { SWRConfig } from 'swr';
import * as Amplitude from '@amplitude/analytics-browser';

declare global {
	interface Window {
		naver: any;
		gotoLoginAccess: any;
		recaptchaVerifier: any;
		confirmationResult: any;
	}
}

function App() {
	const swrConfig = {
		fetcher(url: string, params: object) {
			axios()
				.get(url, { params })
				.then((res) => res.data);
		},
	};
	const amplitudeApi = process.env.REACT_APP_AMPLITUDE_API_KEY ?? '';

	useEffect(() => {
		if (!getCookie('amplitude_custom_id')) {
			const amplitudeCustomId = uuid();
			setCookie('amplitude_custom_id', amplitudeCustomId, 300000, '.knowingdoctor.com');
			Amplitude.init(amplitudeApi, amplitudeCustomId);
		} else {
			Amplitude.init(amplitudeApi, getCookie('amplitude_custom_id'));
		}

		var eventProperties = {
			path: window.location.pathname,
			host: window.location.hostname,
			href: window.location.href,
			platform: 'website',
			queryString: window.location.search,
		};
		Amplitude.track('PARTNER-Viewed', eventProperties);
	}, []);

	function getCookie(name: string) {
		const cookieName = encodeURIComponent(name) + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookieArray = decodedCookie.split(';');

		for (let i = 0; i < cookieArray.length; i++) {
			let cookie = cookieArray[i];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(cookieName) === 0) {
				return cookie.substring(cookieName.length, cookie.length);
			}
		}
		return undefined;
	}

	function uuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	function setCookie(name: string, value: string, days: number, domain: string) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		const expires = `; expires=${date.toUTCString()}`;
		const domainOption = domain ? `; domain=${domain}` : '';
		document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
			value
		)}${expires}${domainOption}; path=/`;
	}

	return (
		<SWRConfig value={swrConfig}>
			<div className='App'>
				<Router />
			</div>
		</SWRConfig>
	);
}

export default App;
