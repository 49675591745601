import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckBoxIcon } from 'assets/icon/login/checkBox.svg';

interface RequirementItemProps {
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}
export function IsCheck({ onClick }: RequirementItemProps) {
	return (
		<div onClick={onClick}>
			<IsCheckBox />
		</div>
	);
}

const IsCheckBox = styled(CheckBoxIcon)`
	min-width: 20px;
	cursor: pointer;
`;
