import axios, { AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

export async function getHospitalList(doctorId: string | null) {
	const res = await axiosInstance.get(`/doctor/${doctorId}/hospital`);
	return res.data;
}
