import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useAxios from "api/axiosCustom";
import { HeaderBar } from "components/header";
import { Switch } from "antd";
import styleConstant from "data/constant/styleConstant";
import menuConstant from "data/constant/menuConstant";
import routerConstant from "data/constant/routerConstant";

export default function MyInfoUpdatePage() {
  const axios = useAxios();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAlarm, setIsAlarm] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await axios.get("/user");
      setIsAlarm(res.data.isKakaoAlarm);
      setIsLoading(false);
    })();
  }, []);

  const handleKakaoAlertChange = async () => {
    setIsLoading(true);
    const res = await axios.post("/user/alarm/kakao");
    setIsAlarm((prev) => !prev);
    setIsLoading(false);
  };

  return (
    <Cover>
      <HeaderBar noBack={true} nowPath={menuConstant.MY_INFO} />
      <SettingContainer>
        <p className="settingTitle">새 메시지 알림</p>
        <KakaoAlarm>
          <p>카카오 알림톡</p>
          <CustomSwitch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={handleKakaoAlertChange}
            checked={isAlarm}
            loading={isLoading}
          />
        </KakaoAlarm>
      </SettingContainer>
    </Cover>
  );
}

const Cover = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

const SettingContainer = styled.div`
  width: 400px;
  margin: 48px auto 0;
  .settingTitle {
    font-size: ${styleConstant.FONT_SIZE.large};
    font-weight: bold;
  }
`;

const KakaoAlarm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    font-size: ${styleConstant.FONT_SIZE.medium};
  }
  .ant-switch-checked {
    background-color: #27b4eb !important;
  }
`;

const CustomSwitch = styled(Switch)`
  margin: 2px 0 0;
  transform: scale(1.1);
`;
