import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { MyChat, InterlocutorChat } from 'components/chat';
import { ChatInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { OneBtnModal, TwoBtnModal } from 'components/modal';
import styleConstant from 'data/constant/styleConstant';
import * as firebase from 'firebase/database';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import {
	getDatabase,
	ref,
	child,
	get,
	update,
	push,
	set,
	onChildAdded,
	onChildChanged,
	off,
	onValue,
} from 'firebase/database';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer/rootReducer';
import routerConstant from 'data/constant/routerConstant';

export function SelectChat({ chat }: any) {
	const location = useLocation();
	const navigate = useNavigate();
	const axios = useAxios();

	const isChat = useSelector((state: RootState) => state.chat.ischat);
	const selectCounseling = useSelector((state: RootState) => state.chat.selectCounseling);

	const [isStartModalVisible, setIsStartModalVisible] = useState<boolean>(false);

	const chatEndPointRef = useRef<any>(null);
	const chatInputRef = useRef<any>(null);
	const fileInputRef = useRef<any>(null);

	const [inputValue, setInputValue] = useState<string>('');
	const [isLeaveModal, setIsLeaveModal] = useState<boolean>(false);
	const [isRestartModal, setIsRestartModal] = useState<boolean>(false);

	const [chatConfig, setChatConfig] = useState<any>({});
	const [chatList, setChatList] = useState<any>([]);
	const [addChat, setAddChat] = useState<any>({});
	const [isSend, setIsSend] = useState<boolean>(true);
	const [isEdit, setIsEdit] = useState<any>(null);
	const [file, setFile] = useState<any>();

	const firebaseConfig = {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	};
	const firebaseApp = initializeApp(firebaseConfig);
	const database = getDatabase(firebaseApp);
	const auth = getAuth(firebaseApp);

	useEffect(() => {
		signInAnonymously(auth)
			.then(() => {})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
			});

		onAuthStateChanged(auth, (user) => {
			if (user) {
				const uid = user.uid;
			} else {
			}
		});
	}, []);

	useEffect(() => {
		if (!chat.realtimeManagerId) {
			return;
		} else {
			setChatList([]);
			let config: any = {};
			get(
				child(ref(database), `server/chat/ChatRooms/${chat.realtimeChatRoomId}`)
			).then((snapshot) => {
				if (snapshot.exists()) {
					setChatConfig(snapshot.val());
					config = snapshot.val();
					const chatRoomData = {
						...config,
						doctorCount: 0,
						updatedAt: firebase.serverTimestamp(),
					};

					const chatRoom: any = {};
					chatRoom[`server/chat/ChatRooms/${chat.realtimeChatRoomId}`] =
						chatRoomData;
					update(ref(database), chatRoom);
				} else {
					console.log('No data available');
				}
			});

			// get once
			// get(child(ref(database), `server/chat/Messages/${chat.realtimeChatRoomId}`)).then(
			// 	(snapshot) => {
			// 		if (snapshot.exists()) {
			// 			const value = snapshot.val();
			// 			const entry = Object.entries(value);
			// 			let arr: any = [];
			// 			entry.map((element: any, idx) =>
			// 				arr.push({ ...element[1], mid: element[0] })
			// 			);
			// 			setChatList(arr);
			// 			console.log('arr', value, arr);
			// 		} else {
			// 			console.log('No data available');
			// 		}
			// 	}
			// );

			// realTIme
			const chatDataRef = child(
				ref(database),
				`server/chat/Messages/${chat.realtimeChatRoomId}`
			);
			onValue(chatDataRef, (snapshot) => {
				const value = snapshot.val();
				const entry = Object.entries(value);
				let arr: any = [];
				entry.map((element: any, idx) =>
					arr.push({ ...element[1], mid: element[0] })
				);
				setChatList(arr);
			});

			const addListner = onChildAdded(
				ref(database, `server/chat/Messages/${chat.realtimeChatRoomId}`),
				(data) => {
					setAddChat({ ...data.val(), mid: data.key });
				}
			);
			const changedListner = onChildChanged(
				ref(database, `server/chat/ChatRooms/${chat.realtimeChatRoomId}`),
				(data) => {
					let changeConfig: any = {};
					get(
						child(
							ref(database),
							`server/chat/ChatRooms/${chat.realtimeChatRoomId}`
						)
					).then((snapshot) => {
						if (snapshot.exists()) {
							changeConfig = snapshot.val();
							if (changeConfig.doctorCount === 1) {
								const chatRoomData = {
									...changeConfig,
									doctorCount: 0,
									updatedAt: firebase.serverTimestamp(),
								};
								setChatConfig(chatRoomData);
								const chatRoom: any = {};
								chatRoom[
									`server/chat/ChatRooms/${chat.realtimeChatRoomId}`
								] = chatRoomData;
								update(ref(database), chatRoom);
							} else {
								setChatConfig(snapshot.val());
							}
						} else {
							console.log('No data available');
						}
					});
				}
			);
		}
		return () => {
			off(
				ref(database, `server/chat/Messages/${chat.realtimeChatRoomId}`),
				'child_added'
			);
			off(
				ref(database, `server/chat/ChatRooms/${chat.realtimeChatRoomId}`),
				'child_changed'
			);
		};
	}, [chat]);

	// useEffect(() => {
	// 	setChatList([...chatList, addChat]);
	// }, [addChat]);

	useEffect(() => {
		// chatEndPointRef.current.scrollIntoView({ behavior: "smooth" });
		chatEndPointRef.current?.scrollIntoView(false);
	}, [chatList]);

	function onLeaveModalCancelBtn() {
		setIsLeaveModal(false);
	}

	function onRestartModalCancelBtn() {
		setIsRestartModal(false);
	}

	async function onLeveModalConfirmBtn() {
		const chatRoomData = {
			...chatConfig,
			doctorCount: 0,
			updatedAt: firebase.serverTimestamp(),
			isEnd: true,
		};
		setChatConfig(chatRoomData);
		const chatRoom: any = {};
		chatRoom[`server/chat/ChatRooms/${chat.realtimeChatRoomId}`] = chatRoomData;
		update(ref(database), chatRoom);

		const res = await axios.put(`counseling/chat/${chat.id}`, { isEnd: true });
		navigate(`${routerConstant.COUNSELING}/${location.state.id}`, {
			state: { ...location.state, menuState: '상담하기' },
		});
		navigate(0);
	}

	async function onRestartModalConfirmBtn() {
		const chatRoomData = {
			...chatConfig,
			patienttCount: 1,
			updatedAt: firebase.serverTimestamp(),
			isEnd: false,
		};
		setChatConfig(chatRoomData);
		const chatRoom: any = {};
		chatRoom[`server/chat/ChatRooms/${chat.realtimeChatRoomId}`] = chatRoomData;
		update(ref(database), chatRoom);

		const res = await axios.put(`counseling/chat/${chat.id}`, { isEnd: false });
		navigate(`${routerConstant.COUNSELING}/${location.state.id}`, {
			state: { ...location.state, menuState: '상담하기' },
		});
		navigate(0);
	}

	function onChatLeaveBtn() {
		setIsLeaveModal(true);
	}

	function onChatRestartBtn() {
		setIsRestartModal(true);
	}

	function onInputChange(e: any) {
		!file?.src && setInputValue(e.target.value);
	}

	async function onInputSubmitBtn() {
		if (inputValue.length > 0 && isSend) {
			const sendMsg = inputValue;
			setIsSend(false);
			setInputValue('');
			const chatRoomData = {
				...chatConfig,
				doctorCount: 0,
				patientCount: chatConfig.patientCount + 1,
				lastMessage: sendMsg,
				updatedAt: firebase.serverTimestamp(),
				patientCountUpdatedAt: firebase.serverTimestamp(),
			};

			const chatRoom: any = {};
			chatRoom[`server/chat/ChatRooms/${chat.realtimeChatRoomId}`] = chatRoomData;

			const messagesRef = ref(
				database,
				`server/chat/Messages/${chat.realtimeChatRoomId}`
			);
			const newMessagesRes = push(messagesRef);
			await update(ref(database), chatRoom);
			await set(newMessagesRes, {
				message: sendMsg,
				type: 'message',
				uid: chat.realtimeDoctorId,
				createdAt: firebase.serverTimestamp(),
			});
			setFile({});
			fileInputRef.current.value = '';
			setTimeout(() => {
				setIsSend(true);
			}, 500);
		}
	}
	function onInputEnterKey(e: any) {
		if (e.shiftKey && e.keyCode === 13) {
			e.preventDefault();
			isEdit === null ? onInputSubmitBtn() : onInputEditBtn(isEdit);
		}
	}

	const onInputEditBtn = async (msg: any) => {
		if (inputValue.length > 0 && isSend) {
			const sendMsg = inputValue + ' (수정됨)';
			setIsSend(false);
			setInputValue('');

			const chatRoomData = {
				...chatConfig,
				doctorCount: 0,
				lastMessage: sendMsg,
				updatedAt: firebase.serverTimestamp(),
			};

			const chatRoom: any = {};
			chatRoom[`server/chat/ChatRooms/${chat.realtimeChatRoomId}`] = chatRoomData;
			await update(ref(database), chatRoom);

			set(
				child(
					ref(database),
					`server/chat/Messages/${chat.realtimeChatRoomId}/${msg.mid}`
				),
				{ ...msg, message: inputValue }
			);

			setIsEdit(null);
			setTimeout(() => {
				setIsSend(true);
			}, 500);
		}
	};

	const onClickDeleteBtn = async (mid: string) => {
		const chatRoomData = {
			...chatConfig,
			doctorCount: 0,
			lastMessage: '삭제된 메세지',
			updatedAt: firebase.serverTimestamp(),
		};

		const chatRoom: any = {};
		chatRoom[`server/chat/ChatRooms/${chat.realtimeChatRoomId}`] = chatRoomData;
		await update(ref(database), chatRoom);
		set(
			child(ref(database), `server/chat/Messages/${chat.realtimeChatRoomId}/${mid}`),
			null
		);
	};

	function onStartModal() {
		setIsStartModalVisible(true);
	}

	async function onChatStartBtn() {
		const res = await axios.post(`/counseling/doctor/${location.state.hospitalId}/chat`, {
			userCounselingId: selectCounseling.id,
		});
		setIsStartModalVisible(false);
		navigate(`${routerConstant.COUNSELING}/${location.state.id}`, {
			state: { ...location.state, menuState: '상담하기' },
		});
		navigate(0);
	}

	const onClickWrap = () => {
		setIsEdit(null);
		setInputValue('');
	};
	useEffect(() => {
		setFile({});
		fileInputRef.current.value = '';
	}, [isEdit]);

	return (
		<>
			<Cover>
				<TwoBtnModal
					isModalVisible={isLeaveModal}
					mainText='상담을 종료하시겠어요?'
					leftBtnText='아니요'
					leftBtnClick={onLeaveModalCancelBtn}
					rightBtnText='종료하기'
					rightBtnClick={onLeveModalConfirmBtn}
				/>
				<TwoBtnModal
					isModalVisible={isRestartModal}
					mainText='이미 종료된 상담입니다.'
					bottomText='만약 더 남기실 말씀이 있으시다면 아래의 버튼을 눌러 상담을 다시 열어주세요'
					leftBtnText='취소'
					leftBtnClick={onRestartModalCancelBtn}
					rightBtnText='다시 열기'
					rightBtnClick={onRestartModalConfirmBtn}
				/>
				<TwoBtnModal
					isModalVisible={isStartModalVisible}
					mainText='상담을 시작하시겠어요?'
					leftBtnText='아니요'
					leftBtnClick={() => setIsStartModalVisible(false)}
					rightBtnText='시작하기'
					rightBtnClick={onChatStartBtn}
				/>
				<ChatBox>
					<Wrap isEdit={isEdit} onClick={onClickWrap} />
					{!isChat ? (
						<>
							<div ref={chatEndPointRef} className='startInfoText'>
								버튼을 눌러 대화를 시작해 주세요
							</div>
							<div className='btnContainer'>
								<PrimaryBtn
									label='상담 시작하기'
									onClick={onStartModal}
								/>
							</div>
						</>
					) : (
						<>
							{chatList.map((element: any, idx: number) =>
								element.uid === chat.realtimeDoctorId ? (
									<MyChat
										key={idx}
										onClickDeleteBtn={onClickDeleteBtn}
										setIsEdit={setIsEdit}
										isEdit={isEdit}
										setInputValue={setInputValue}
										isRead={
											idx >=
											chatList.length -
												chatConfig.patientCount
												? chatConfig.patientCount >
												  0
												: false
										}
										msg={element}
										msgDate={moment(
											element.createdAt
										).format('MM월 DD일 HH:mm')}
									/>
								) : element.uid === chat.realtimePatientId ? (
									<InterlocutorChat
										key={idx}
										name={chat.Patient.name}
										msgText={element.message}
										msgDate={moment(
											element.createdAt
										).format('MM월 DD일 HH:mm')}
									/>
								) : idx === 0 ? (
									<p className='startPoint'>
										{element.message}
									</p>
								) : (
									<InterlocutorChat
										key={idx}
										name='관리자'
										msgText={element.message}
										msgDate={moment(
											element.createdAt
										).format('MM월 DD일 HH:mm')}
									/>
								)
							)}
							{chat.isEnd || chatConfig.isEnd ? (
								<p
									className='endPoint'
									ref={chatEndPointRef}
									onClick={onChatRestartBtn}>
									이미 종료된 대화입니다.
									<span> 상담 다시 열기</span>
								</p>
							) : (
								<p
									className='endPoint'
									ref={chatEndPointRef}
									onClick={onChatLeaveBtn}>
									대화를 종료하시려면 <span>클릭</span>해주세요.
								</p>
							)}
						</>
					)}
				</ChatBox>
				<ChatInput
					isEnd={chat.isEnd || chatConfig.isEnd}
					isStart={!isChat}
					onKeyDown={onInputEnterKey}
					inputRef={chatInputRef}
					value={inputValue}
					setValue={setInputValue}
					isEdit={isEdit}
					placeholder={
						isEdit === null
							? '하실 말씀을 입력해주세요.'
							: '수정하실 말씀을 입력해주세요.'
					}
					type='submit'
					file={file}
					setFile={setFile}
					fileInputRef={fileInputRef}
					onChange={onInputChange}
					onClick={() =>
						isEdit === null ? onInputSubmitBtn() : onInputEditBtn(isEdit)
					}
				/>
				<div className='blank' />
			</Cover>
		</>
	);
}

const Cover = styled.section`
	box-sizing: border-box;
	position: relative;
	width: 664px;
	min-height: 550px;
	height: 60vh;
	margin: 0 32px 0 0;
	padding: 24px;
	background-color: #f8f8f8;
	border-radius: 16px;
`;

const ChatBox = styled.div`
	height: 47vh;
	min-height: 421px;
	margin-bottom: 30px;
	overflow: auto;
	overflow-y: overlay;
	overflow-x: hidden;
	&:hover {
		&::-webkit-scrollbar {
			display: block;
		}
	}
	&::-webkit-scrollbar {
		width: 5px;
		display: none;
	}
	&::-webkit-scrollbar-thumb {
		height: 20%;
		background-color: #cfcfcf;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-track {
		background-color: #fff;
	}
	.startPoint {
		font-size: ${styleConstant.FONT_SIZE.small};
		margin-top: 34px;
		margin-bottom: 24px;
		text-align: center;
	}
	.endPoint {
		width: 250px;
		font-size: ${styleConstant.FONT_SIZE.small};
		text-align: center;
		margin: 24px auto 0 auto;
		/* margin-top: 24px; */
		cursor: pointer;
		word-break: keep-all;
		span {
			color: #1bbcff;
			font-weight: bold;
		}
	}

	.startInfoText {
		font-size: ${styleConstant.FONT_SIZE.small};
		text-align: center;
		margin: 0;
	}
	.btnContainer {
		margin: 16px 0 0;
		display: flex;
		justify-content: center;
		button {
			width: 210px;
		}
	}
`;

const Wrap = styled.div<{ isEdit: string | null }>`
	@keyframes in {
		0% {
			opacity: 0%;
		}
		100% {
			opacity: 100%;
		}
	}
	@keyframes out {
		100% {
			opacity: 0%;
		}
	}
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(1.5px);
	z-index: ${({ isEdit }) => (isEdit !== null ? 100 : 0)};
	border-radius: 16px;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	pointer-events: ${({ isEdit }) => (isEdit !== null ? '' : 'none')};
	animation-name: ${({ isEdit }) => (isEdit !== null ? 'in' : 'out')};
	animation-fill-mode: forwards;
`;
